import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Sidebar } from 'semantic-ui-react';

import usePermissions from '@hooks/usePermissions';

import Logo from './Logo';
import SidebarItem from './SidebarItem';
import UserSection from './UserSection';

export const SidebarWidth = Object.freeze({
  Thin: 'thin',
  VeryThin: 'very thin',
});

export default function AriaSidebar(props) {
  const [width, setWidth] = useState(SidebarWidth.VeryThin);
  const { t } = useTranslation();
  const { canViewDashboard, canViewSettings } = usePermissions();
  const toggleSidebarWidth = () => {
    setWidth((prevWidth) => {
      return prevWidth === SidebarWidth.Thin
        ? SidebarWidth.VeryThin
        : SidebarWidth.Thin;
    });
  };

  return (
    <Sidebar.Pushable>
      <Sidebar
        as={Menu}
        animation="overlay"
        className="aria-sidebar"
        icon="labeled"
        vertical
        visible
        width={width}
      >
        <Logo
          toggleWidth={toggleSidebarWidth}
          width={width}
          isSidebarDisabled={props.isSidebarDisabled}
        />
        <SidebarItem
          key="transflo"
          width={width}
          componentName={t('Transflo IA')}
          url="/"
          icon="th large"
          toggleSideBar={toggleSidebarWidth}
          isSidebarDisabled={props.isSidebarDisabled}
        />
        {canViewDashboard && (
          <SidebarItem
            key="dashboard"
            width={width}
            componentName={t('Dashboards')}
            url="/dashboard"
            icon="chart line"
            toggleSideBar={toggleSidebarWidth}
            isSidebarDisabled={props.isSidebarDisabled}
          />
        )}

        {/* {currentUser?.roles.includes('super_admin') && (
          <>
            <SidebarItem
              width={width}
              componentName={t('Companies')}
              url="/companies"
              icon="building outline"
              toggleSideBar={toggleSidebarWidth}
            />
            <SidebarItem
              width={width}
              componentName={t('Services')}
              url="/services"
              icon="cog"
              toggleSideBar={toggleSidebarWidth}
            />
          </>
        )}
        {currentUser?.permittedApps?.map((app) => (
          <SidebarItem
            key={app.id}
            width={width}
            componentName={app.name}
            url={`/applications/${app.id}`}
            icon="list"
            toggleSideBar={toggleSidebarWidth}
          />
        ))}
        */}
        {canViewSettings && (
          <SidebarItem
            width={width}
            componentName={t('Settings')}
            url="/admin"
            icon="cog"
            toggleSideBar={toggleSidebarWidth}
            isSidebarDisabled={props.isSidebarDisabled}
          />
        )}
        <UserSection width={width} />
      </Sidebar>

      <Sidebar.Pusher as="main">
        <div
          className={`app-container ${width} ${
            props?.noPadding ? 'noPadding' : ''
          }`}
        >
          {props.children}
        </div>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
}
