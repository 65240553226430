import Jsona from 'jsona';

import api from '@services/axios';

export const loginUser = async (email, password) => {
  const dataFormatter = new Jsona();
  try {
    const response = await api.restAPI().post('/users/sign_in', {
      user: {
        email,
        password,
      },
    });

    const { jwt } = dataFormatter.deserialize(response.data);
    return jwt;
  } catch (err) {
    const serverError =
      err?.response?.data?.error ||
      'We are experiencing technical difficulties. We apologize for any inconvenience.';
    throw new Error(serverError);
  }
};
