import React, { useEffect } from 'react';
import { Button, Input, Select } from 'antd';

import { downloadFile } from './api/api';

const selectModes = {
  default: 'default',
  multiselect: 'multiple',
  tags: 'tags',
};

const SectionInput = ({ field, onChange }) => {
  const [value, setValue] = React.useState(null);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  const onDownloadFile = async (url) => {
    await downloadFile(url, field.name);
  };

  const renderSectionInput = (input) => {
    switch (input.type) {
      case 'password': {
        return (
          <Input.Password
            onBlur={(e) =>
              onChange({ value: e.target.value, name: field.name })
            }
            type={input.type === 'password'}
            onChange={handleChange}
            placeholder={input.default}
            value={value}
            disabled={input.disabled}
          />
        );
      }
      case 'input': {
        return (
          <Input
            onBlur={(e) =>
              onChange({ value: e.target.value, name: field.name })
            }
            type={input.type === 'password'}
            onChange={handleChange}
            placeholder={input.default}
            value={value}
            disabled={input.disabled}
          />
        );
      }
      case 'checkbox': {
        return (
          <Input
            onChange={(e) => {
              onChange({ value: e.target.checked, name: field.name });
              setValue(e.target.checked);
            }}
            type="checkbox"
            checked={value}
            disabled={input.disabled}
          />
        );
      }
      case 'tags':
      case 'multiselect':
      case 'select': {
        return (
          <Select
            disabled={input.disabled}
            style={{ width: '100%' }}
            onChange={(selectValue) => {
              onChange({ value: selectValue, name: field.name });
              setValue(selectValue);
            }}
            mode={selectModes[input.type]}
            value={value}
            options={input.options.map((option) => {
              return {
                value: option,
                label: option,
              };
            })}
          />
        );
      }
      case 'file': {
        return (
          <>
            <h1>onDownloadFile</h1>
            <Button
              disabled={!input.value}
              onClick={() => onDownloadFile(input.value)}
            >
              Download
            </Button>
          </>
        );
      }
      default: {
        return null;
      }
    }
  };
  return renderSectionInput(field);
};

export default SectionInput;
