/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import { useState, useEffect } from 'react';
import { Button, Spin, Tag, Tooltip } from 'antd';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { useDrop } from 'react-dnd';
import { Icon as SuiIcon, Popup as SuiPopup } from 'semantic-ui-react';
import clsx from 'clsx';

import s from '@components/WorkItemCard/styles.module.scss';
import { statusColors, WORK_ITEM_STATUSES } from '@constants';
import DocumentCard from '@components/DocumentCard/DocumentCard';
import AddDocumentButton from '@components/AddDocumentButton/AddDocumentButton';
import NewModal from '@components/Modal/NewModal';
import { dayjsWithTimezone } from '@services/helpers';
import config from '@config';

const WorkItemCard = ({
  workItem,
  onDocumentDrop,
  onPageDrop,
  onWorkItemRemove,
  onAddDocument,
  onMovePageToNewDocument,
  onCloseDocument,
  workItems,
  onDownloadDocument,
  onWorkItemOpen,
  from,
  onPageClick,
  onDocumentTypeChange,
  defaultOpen = false,
  readOnly,
  onDocumentRemove,
  removable,
  onSelectPage,
  selectedPages,
  onClone,
  hasError = false,
  hasUnsavedChanges,
}) => {
  const [open, setOpen] = useState(defaultOpen || false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [cloneModalVisible, setCloneModalVisible] = useState(false);

  const [, drop] = useDrop(
    () => ({
      accept: readOnly ? '' : 'document',
      drop: (item) => {
        onDocumentDrop({
          documentId: item.id,
          newWorkItemId: workItem.id,
          oldWorkItemId: item.wiId,
          from: item.from,
          to: from,
        });
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [workItem, workItems],
  );

  const toggleCardClick = () => {
    if (!open && onWorkItemOpen && !removeModalVisible && !cloneModalVisible) {
      onWorkItemOpen(workItem);
    }
    if (!removeModalVisible && !cloneModalVisible) {
      setOpen(!open);
    }
  };

  const date = dayjsWithTimezone(workItem?.receivedAt).format('MM/DD/YYYY');

  const isGrayBackground = ![
    WORK_ITEM_STATUSES.needsAttention,
    WORK_ITEM_STATUSES.paused,
    WORK_ITEM_STATUSES.replied,
    WORK_ITEM_STATUSES.finalReview,
  ].includes(workItem?.status?.name);

  useEffect(() => {
    console.log(hasUnsavedChanges);
  }, [hasUnsavedChanges]);

  return (
    <div
      ref={drop}
      className={clsx(
        s.card,
        isGrayBackground && s.grayBackground,
        hasError && hasUnsavedChanges && s.errorWi,
      )}
      onClick={toggleCardClick}
      id="card"
    >
      {hasError && hasUnsavedChanges && (
        <SuiPopup
          content="You have updated a Work Item without documents. Please attach a document or remove the Work Item before leaving the page."
          trigger={<SuiIcon name="warning circle" color="red" />}
          position="top center"
        />
      )}
      {cloneModalVisible && (
        <NewModal
          maskClosable={false}
          onClick={(e) => {
            e.stopPropagation();
          }}
          title="Clone work item confirm"
          onCancel={(e) => {
            e.stopPropagation();
            setCloneModalVisible(false);
          }}
          visible
          onCrossClick={(e) => {
            e.stopPropagation();

            setCloneModalVisible(false);
          }}
          onSubmit={(e) => {
            e.stopPropagation();
            setCloneModalVisible(false);
            onClone(workItem);
          }}
        >
          {`Do you want to clone work item ${workItem?.id}?`}
        </NewModal>
      )}
      {removeModalVisible && (
        <NewModal
          maskClosable={false}
          onClick={(e) => {
            e.stopPropagation();
          }}
          title="Remove work item confirm"
          onCancel={(e) => {
            e.stopPropagation();
            setRemoveModalVisible(false);
          }}
          visible={removeModalVisible}
          onCrossClick={(e) => {
            e.stopPropagation();

            setRemoveModalVisible(false);
          }}
          onSubmit={(e) => {
            e.stopPropagation();
            setRemoveModalVisible(false);
            onWorkItemRemove({ id: workItem.id, from });
          }}
        >
          {`Do you want to delete work item ${workItem?.id}?`}
        </NewModal>
      )}
      <div className={s.cardHeader}>
        <div className={s.cardHeaderTitle}>
          Load number: {workItem?.loadNumber || 'N/A'}
          <a
            className={s.wiLink}
            onClick={(event) => event.stopPropagation()}
            target="_blank"
            href={`${config.api.url.http}/workitems/${workItem.id}`}
            rel="noreferrer"
          >
            (WI#{workItem.id})
          </a>
        </div>
        <div className={s.cardHeader}>
          <div className={s.cloneButton}>
            <Button
              type="secondary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setCloneModalVisible(true);
              }}
            >
              Clone
            </Button>
          </div>
          <Tag
            style={{ width: '116px', textAlign: 'center', marginRight: 24 }}
            color={statusColors.get(workItem?.status?.name)}
          >
            {workItem?.status?.caption}
          </Tag>
          {removable && (
            <SuiIcon
              name="trash"
              style={{
                cursor: 'pointer',
                color: '#918f8c',
              }}
              onClick={(e) => {
                e.stopPropagation();
                setRemoveModalVisible(true);
              }}
            />
          )}
          {open ? (
            <CaretRightOutlined
              style={{ color: '#918f8c' }}
              onClick={() => setOpen(!open)}
            />
          ) : (
            <CaretDownOutlined
              style={{ color: '#918f8c' }}
              onClick={() => {
                onWorkItemOpen(workItem);
                setOpen(!open);
              }}
            />
          )}
        </div>
      </div>
      <div className={s.cardInfo}>
        <div>Client: {workItem?.outputFields?.client?.name}</div>
        <div>Batch# {workItem?.batch?.data?.id}</div>
        <div>{date}</div>
      </div>
      <div className={s.cardMailInfo}>
        <div>Email: {workItem?.batch?.data?.attributes?.email || 'N/A'}</div>
        <div>
          Subject: {workItem?.batch?.data?.attributes?.subjectLine || 'N/A'}
        </div>
      </div>
      {workItem?.isLoading && (
        <div className={s.documentLoader}>
          <Spin />
        </div>
      )}
      {workItem?.documents?.length > 0 && open && (
        <div className={s.documents}>
          {workItem.documents
            .filter(
              (document) =>
                !document?.discarded && document?.layout_name !== 'Audit Log',
            )
            .sort((a, b) => {
              if (
                a.layout_name === 'Unassigned' &&
                b.layout_name !== 'Unassigned'
              ) {
                return 1;
              }
              if (
                a.layout_name !== 'Unassigned' &&
                b.layout_name === 'Unassigned'
              ) {
                return -1;
              }
              return new Date(a?.created_at) - new Date(b?.created_at);
            })
            .map((document) => (
              <DocumentCard
                defaultOpen={defaultOpen}
                from={from}
                key={document.id}
                document={document}
                wiId={workItem.id}
                onPageDrop={onPageDrop}
                onCloseDocument={onCloseDocument}
                onDownloadDocument={onDownloadDocument}
                onPageClick={onPageClick}
                onDocumentTypeChange={onDocumentTypeChange}
                readOnly={readOnly}
                onRemove={onDocumentRemove}
                onSelectPage={onSelectPage}
                selectedPages={selectedPages}
              />
            ))}
        </div>
      )}

      {open && !workItem?.isLoading && !readOnly && (
        <AddDocumentButton
          wiId={workItem.id}
          from={from}
          onClick={() => onAddDocument({ wiId: workItem.id, from })}
          onMovePageToNewDocument={onMovePageToNewDocument}
        />
      )}
    </div>
  );
};

export default WorkItemCard;
