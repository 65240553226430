import { Modal, Select, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { toast } from 'react-hot-toast';

import s from '@components/EmailClient/EmailClient.module.scss';
import NewEmailForm from '@components/forms/NewEmailForm/NewEmailForm';
import EmailsList from '@components/EmailClient/components/EmailsList/EmailsList';
import { getUniqueEmails } from '@components/EmailClient/helpers';
import { setWorkItem } from '@actions';

import {
  fetchEmails,
  fetchEmailTemplates,
  fetchEmailAddresses,
  fetchContactEmails,
  sendEmail,
} from './api/api';

const EmailClient = ({ onClose, readOnly }) => {
  const dispatch = useDispatch();
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [savedEmails, setSavedEmails] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [isNewEmailOpen, setIsNewEmailOpen] = useState(false);
  const [savedCCEmails, setSavedCCEmails] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const currentWorkItem = useSelector(
    (state) => state.workItemReducer.workItem,
  );
  const documents = useSelector(
    (state) => state.workItemReducer.workItem.documents,
  ).filter(
    (document) =>
      !document.discarded &&
      selectedTemplate?.documentTypes?.includes(document.layout_name),
  );
  const onTemplateSelect = (templateId) => {
    const selected = templates.find((template) => template.id === templateId);
    if (selected?.id) {
      setSelectedTemplate(selected);
      if (!isNewEmailOpen) {
        setIsNewEmailOpen(true);
      }
    } else {
      setSelectedTemplate({});
    }
  };

  const clientEmail =
    useSelector(
      (state) => state?.workItemReducer?.workItem?.outputFields?.client?.email,
    ) || null;
  const onSubmit = async (values) => {
    setLoading(true);
    setIsNewEmailOpen(false);
    const data = {
      email: {
        ...values,
      },
      workItemId: currentWorkItem.id,
    };
    const formData = new FormData();

    Object.keys(data).forEach((dataKey) => {
      if (dataKey === 'email') {
        Object.keys(data[dataKey]).forEach((previewKey) => {
          if (previewKey === 'attachments') {
            data[dataKey][previewKey].forEach((file) => {
              formData.append(
                'email[attachments][]',
                file?.originFileObj || file.key,
              );
            });
          } else {
            formData.append(`email[${previewKey}]`, data[dataKey][previewKey]);
          }
        });
      } else {
        formData.append(dataKey, data[dataKey]);
      }
    });
    try {
      const res = await sendEmail(formData);
      const updatedWI = res.data.data.attributes.workItem.data;

      toast.success('Email was sent successfully');
      setEmails([res.data.data, ...emails]);
      dispatch(
        setWorkItem({
          id: updatedWI.id,
          type: updatedWI.type,
          ...updatedWI.attributes,
        }),
      );
      return res;
    } catch (err) {
      setIsNewEmailOpen(true);
      throw err.response.data;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const loadEmailClientData = async () => {
      setLoading(true);

      try {
        const emailResponse = await fetchEmails(currentWorkItem.id);
        setEmails(emailResponse.data.data);

        const templatesResponse = await fetchEmailTemplates(currentWorkItem.id);
        setTemplates(templatesResponse.data);

        const savedCCEmailsResponse = await fetchEmailAddresses(
          currentWorkItem.id,
        );
        setSavedCCEmails(savedCCEmailsResponse.data);

        if (currentWorkItem?.outputFields?.client?.id) {
          const contactEmailsResponse = await fetchContactEmails(
            currentWorkItem.outputFields.client.id,
          );
          setSavedEmails(contactEmailsResponse.data.data);
        }
      } finally {
        setLoading(false);
      }
    };

    loadEmailClientData();
  }, [currentWorkItem]);

  const defaultTOEmail = [currentWorkItem?.outputFields?.client?.email].filter(
    (email) => email,
  );

  const mappedSavedEmails = savedEmails.map((email) => ({
    label: email.attributes.email,
    value: email.attributes.email,
  }));

  const mappedCCEmails = savedCCEmails.map((email) => ({
    label: email.email,
    value: email.email,
  }));
  if (clientEmail) {
    mappedSavedEmails.push({ label: clientEmail, value: clientEmail });
  }

  const templatesOptions = templates.map((template) => ({
    label: template?.title,
    value: template?.id,
  }));

  const selectedByTemplateDocumentsFromWI =
    documents?.map((document) => ({
      key: document.id,
      label: `${document.layout_name} ${document?.id && `#${document.id}`}`,
      size: document.byte_size,
    })) || [];
  return (
    <Modal
      open
      onCancel={() => {}}
      centered
      destroyOnClose
      title=""
      footer={null}
      width={745}
      closable={false}
      style={{ padding: '0px 10px' }}
    >
      <div className={s.wrapper}>
        <div className={s.header}>
          <div>Email Client</div>
          <div className={s.headerRight}>
            <Select
              allowClear
              disabled={loading}
              placeholder="Template"
              onChange={onTemplateSelect}
              options={templatesOptions}
              className={s.templateSelector}
            />
            <div onClick={onClose} className={s.closeIcon}>
              <CloseOutlined />
            </div>
          </div>
        </div>
        <div className={s.content}>
          {loading && (
            <div className={s.loaderWrapper}>
              <Spin size="large" />
            </div>
          )}
          <NewEmailForm
            readOnly={readOnly}
            visible={(!loading && !emails.length) || isNewEmailOpen}
            toOptions={getUniqueEmails([defaultTOEmail, ...mappedSavedEmails])}
            clientEmail={currentWorkItem?.outputFields?.client?.email || null}
            debtorEmail={currentWorkItem?.outputFields?.debtor?.email || null}
            ccOptions={getUniqueEmails([...mappedSavedEmails])}
            handleSubmit={onSubmit}
            defaultValues={{
              to: defaultTOEmail,
              cc: [...new Set(mappedCCEmails.map((c) => c.label))],
              from: currentWorkItem.batch.data.attributes.fromEmail,
              body: selectedTemplate?.template,
              toMergeDocuments: selectedTemplate?.toMergeDocuments,
              subject: selectedTemplate?.subject,
              attachments: selectedByTemplateDocumentsFromWI,
              templateId: selectedTemplate?.id,
            }}
          />

          {!loading && emails?.length > 0 && !isNewEmailOpen && (
            <div className={s.emailContent}>
              <div className={s.emailsWrapper}>
                <EmailsList emails={emails} />
              </div>
              <Button
                type="submit"
                className={s.newMailButton}
                onClick={() => setIsNewEmailOpen(true)}
              >
                New email
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EmailClient;
