import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export default function TextFilter({ value: filterValue, onChange }) {
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce(async (_value) => {
      try {
        await onChange(_value);
      } catch (error) {
        console.error(error);
      }
    }, 1000),
    [],
  );

  return (
    <Input
      fluid
      style={{ borderRadius: 4 }}
      placeholder={t('SearchPlaceholder')}
      icon="search"
      suffix={<SearchOutlined />}
      allowClear
      defaultValue={filterValue || ''}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
}
