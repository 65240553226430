import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import useFilters from '@hooks/useFilters';
import api from '@services/axios';
import { FILTER_TYPES } from '@components/aria/UI/Table/Filters/filterTypes';
import Table from '@components/aria/UI/Table/Table';
import { setBreadcrumb } from '@actions';

const userOptions = [
  { label: 'User', value: 'user' },
  { label: 'Admin', value: 'admin' },
  { label: 'Super Admin', value: 'super_admin' },
  { label: 'Approver', value: 'approver' },
  { label: 'Auditor', value: 'auditor' },
];

export default function Users() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filters, updateFilters] = useFilters();
  const [tableMetaData, setTableMetaData] = useState(1);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const capitalizeFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getUsers = () => {
    setLoading(true);
    api
      .jsonAPI()
      .get('users', { params: filters })
      .then((res) => {
        setLoading(false);
        setUsers(res.data);
        setTableMetaData(res.data.meta?.pagy);
      })
      .catch((err) => {
        console.error('Error on getUsers', { error: err });
        toast.error(t('Error'));
      });
  };

  const printUserRole = (roles) => {
    // return roles and role super_admin should be Super Admin
    return roles
      .map((role) => {
        if (role === 'super_admin') {
          return 'Super Admin';
        }
        return capitalizeFirst(role);
      })
      .join(',  ');
  };

  const COLUMNS = [
    { name: t('Name'), key: 'name' },
    { name: t('Email'), key: 'email' },
    {
      name: t('Roles'),
      key: 'roles',
      filter: FILTER_TYPES.SELECT,
      options: userOptions,
      method: printUserRole,
    },
  ];

  const userActions = [
    {
      link: '{id}',
      label: 'Edit',
      icon: 'edit',
      // condition: (user) => !user.roles.includes('super_admin'),
    },
    {
      label: 'Delete',
      icon: 'trash',
      modal: true,
      modalConfig: {
        header: t('DeleteItem', { item: 'User' }),
        text: t('DeleteModalConfirmation'),
        onClick: (id) => deleteUser(id),
        yesButton: t('DeleteItemYes', { item: 'user' }),
      },
      // condition: (user) => !user.roles.includes('super_admin'),
    },
  ];

  useEffect(() => {
    getUsers();
    dispatch(setBreadcrumb('Users'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUsers(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const tableButtons = [
    {
      label: 'Refresh',
      type: 'secondary',
      icon: 'sync',
      handleClick: () => getUsers(filters),
    },
    { label: 'Create', type: 'primary', icon: 'add', link: 'new' },
  ];

  const deleteUser = (id) => {
    api
      .jsonAPI()
      .delete(`/users/${id}`)
      .then(() => {
        toast.success(t('ItemDeleted', { item: 'User' }));
        getUsers();
      })
      .catch((err) => {
        console.error('Error deleting the user', { err });
        toast.error(t('ItemNotDeleted', { item: 'user' }));
      });
  };
  return (
    <div className="admin-table-container">
      <Table
        columns={COLUMNS}
        items={users}
        totalItems={tableMetaData?.count ?? 0}
        buttons={tableButtons}
        actions={userActions}
        loading={loading}
        noItemsKey="No users"
        filters={filters}
        pages={tableMetaData?.last ?? 1}
        updateFilters={updateFilters}
        sessionSettings={{ name: 'USERS' }}
        className="adminTable"
      />
    </div>
  );
}
