import { LOAD_APPLICATIONS } from '@constants/action-types';

const initialState = { apps: [] };

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_APPLICATIONS:
      return {
        ...state,
        apps: action.payload,
      };
    default:
      return state;
  }
};
