import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'antd';
import clsx from 'clsx';
import { Checkbox, Icon } from 'semantic-ui-react';
import { toast } from 'react-hot-toast';
import { t } from 'i18next';

import { setDocumentTypes, setWorkItem } from '@actions';
import DocumentViewer from '@components/aria/Application/Activity/Hyperscience/DocumentViewer/DocumentViewer';
import WorkItemDocumentList from '@components/WorkItemDocumentList';
import { generateAddDocumentsOptions } from '@components/WorkItemDocuments/downloadDropdown';
import UploadFilesModal from '@components/WorkItemDocument/UploadFilesModal';
import NewModal from '@components/Modal/NewModal';

import s from './styles.module.scss';
import {
  getDocumentTypes,
  generateNOA,
  generateInvoice,
  uploadDocument,
  removeDocument,
  restoreDocument,
  applyFilters,
  cropPage,
  stampPage,
} from './api/api';

export default function WorkItemDocuments({ fields, readOnly }) {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showDeletedDocuments, setShowDeletedDocuments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({
    modal: null,
    metadata: {},
  });

  const workItem = useSelector((state) => state.workItemReducer.workItem);
  const cleanOpenModalData = () =>
    setOpenModal({
      modal: null,
      metadata: {},
    });
  const documentTypes = useSelector(
    (state) => state.documentTypesReducer.documentTypes,
  );
  const dispatch = useDispatch();

  const fetchDocumentTypes = async () => {
    try {
      const options = await getDocumentTypes();
      dispatch(setDocumentTypes(options));
    } catch (err) {
      console.error(err);
    }
  };

  const uploadOptions = generateAddDocumentsOptions({
    regenerateInvoice: workItem?.documents
      ?.filter((document) => !document?.discarded)
      ?.some((document) => document?.alias === 'invoice'),
    regenerateNOA: workItem?.documents
      ?.filter((document) => !document?.discarded)
      ?.some((document) => document?.alias === 'generated_noa'),
    canGenerateInvoice: workItem?.flags?.manualInvoiceGeneration,
    canGenerateNOA: workItem?.flags?.manualNoaGeneration,
  });
  const uploadButtonActions = ({ key }) => {
    setOpenModal({ modal: key, metadata: {} });
  };

  const renderModal = ({ modal }) => {
    switch (modal) {
      case 'document': {
        return (
          <UploadFilesModal
            onSubmit={onUploadDocument}
            onCancel={cleanOpenModalData}
            uploadModalOpen
          />
        );
      }
      case 'noa': {
        return (
          <NewModal
            title="Do you want to generate NOA?"
            visible
            submitButtonText="Create"
            onSubmit={handleGenerateNOA}
            onCancel={cleanOpenModalData}
            onCrossClick={cleanOpenModalData}
          >
            Newly created NOA document will appear in WI document list
          </NewModal>
        );
      }
      case 'invoice': {
        return (
          <NewModal
            title="Do you want to generate an Invoice?"
            visible
            submitButtonText="Create"
            onSubmit={handleGenerateInvoice}
            onCancel={cleanOpenModalData}
            onCrossClick={cleanOpenModalData}
          >
            Newly created Invoice document will appear in WI document list
          </NewModal>
        );
      }
      default: {
        return null;
      }
    }
  };

  const handleGenerateNOA = async () => {
    const loadingToast = toast.loading(t('NOAOnGenerating'), {
      duration: Infinity,
    });
    cleanOpenModalData();

    try {
      const data = await generateNOA(workItem.id);
      toast.success(t('NOAIsGenerated'));
      dispatch(setWorkItem(data));
    } catch (error) {
      toast.error(error?.response?.data?.errors?.[0] || t('NOAInNotGenerated'));
    } finally {
      toast.dismiss(loadingToast);
    }
  };

  const handleGenerateInvoice = async () => {
    cleanOpenModalData();

    try {
      const documentType = documentTypes.find(
        (document) => document?.alias === 'invoice',
      )?.value;
      const data = await generateInvoice(workItem.id, documentType);
      toast.success(t('InvoiceIsGenerated'));
      dispatch(setWorkItem(data));
    } catch (error) {
      toast.error(
        error?.response?.data?.errors?.[0] || t('InvoiceInNotGenerated'),
      );
    }
  };

  const onSelectDocument = (documentId) => {
    setSelectedDocument(documentId);
  };

  const onUploadDocument = async ({ file, fileType, noaToAll }) => {
    toast.promise(
      uploadDocument(workItem.id, file, fileType.label, noaToAll).then(
        (data) => {
          dispatch(setWorkItem(data));
        },
      ),
      {
        loading: t('FileIsLoading'),
        success: t('FileIsLoaded'),
        error: (error) =>
          error?.response?.data?.errors?.[0] || 'Error while loading file',
      },
    );
  };

  const handleRemoveDocument = async (id) => {
    try {
      const data = await removeDocument(workItem.id, id);
      toast.success(t('FileRemoved'));
      dispatch(setWorkItem(data));
      setSelectedDocument(null);
    } catch (e) {
      toast.error(t('FileNotRemoved'));
      console.error(e);
    }
  };

  const applyFiltersHandler = async ({ method, value, pageId }) => {
    setIsLoading(true);
    try {
      const data = await applyFilters(
        workItem.id,
        selectedDocument,
        method,
        value,
        pageId,
      );
      toast.success(t('FileFilteredSuccess', { item: method }));
      dispatch(setWorkItem(data));
      setSelectedDocument(selectedDocument);
    } catch (error) {
      toast.error(t('FileFilteredFailed', { item: method }));
    } finally {
      setIsLoading(false);
    }
  };

  const onPageCrop = async (cropParams, pageId) => {
    setIsLoading(true);
    try {
      const data = await cropPage(
        workItem.id,
        selectedDocument,
        cropParams,
        pageId,
      );
      toast.success(t('FileCroppedSuccess'));
      dispatch(setWorkItem(data));
      setSelectedDocument(selectedDocument);
    } catch (error) {
      toast.error(t('FileCroppedFailed'));
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onPageStamp = async ({ x1, x2, y1, y2 }, pageId, stampId) => {
    setIsLoading(true);
    try {
      const data = await stampPage(
        workItem.id,
        selectedDocument,
        { x1, x2, y1, y2 },
        pageId,
        stampId,
      );
      toast.success(t('FileStampedSuccess'));
      dispatch(setWorkItem(data));
      setSelectedDocument(selectedDocument);
    } catch (error) {
      toast.error(t('FileStampedFailed'));
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeDocument = (id) => {
    setSelectedDocument(id);
  };

  const handleRestoreDocument = async (id) => {
    try {
      const data = await restoreDocument(workItem.id, id);
      toast.success(t('FileRestored'));
      dispatch(setWorkItem(data));
      setSelectedDocument(null);
    } catch (e) {
      toast.error(t('FileNotRestored'));
      console.error(e);
    }
  };

  useEffect(() => {
    if (workItem.documents.filter((item) => !item?.discarded).length > 0) {
      setSelectedDocument(
        workItem.documents.filter((item) => !item?.discarded)[0]?.id,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workItem.id]);

  useEffect(() => {
    if (workItem?.documents.length > 0) {
      fetchDocumentTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workItem.id]);
  const currentAttachments =
    workItem?.documents?.find((doc) => doc?.id === selectedDocument)?.pages ||
    [];
  return (
    <section className={s.wiDocuments}>
      {renderModal(openModal)}
      <div className="wi-documents-container">
        <div className={s.wiDocumentsScroll}>
          <div className={s.wiDocumentsListContainer}>
            <div className={s.headerText}>
              <h4 className={s.docsHeading}>Documents</h4>
              <div className={s.showDeleted}>
                Show deleted
                <Checkbox
                  onChange={(_, { checked }) =>
                    setShowDeletedDocuments(checked)
                  }
                />
              </div>
            </div>
            <Dropdown
              disabled={readOnly}
              className={clsx(
                s.addDocumentSelect,
                readOnly && s.readOnlyDropdown,
              )}
              menu={{ items: uploadOptions, onClick: uploadButtonActions }}
              trigger={['click']}
            >
              <div className={clsx('ui', 'primary', 'button', s.button)}>
                Add document <Icon name="angle down" className={s.icon} />
              </div>
            </Dropdown>
          </div>
          <WorkItemDocumentList
            documents={workItem.documents.filter((document) => {
              if (!document.discarded) {
                return true;
              }
              return document.discarded === showDeletedDocuments;
            })}
            selectedDocument={selectedDocument}
            onSelectDocument={onSelectDocument}
            onDocumentRemove={handleRemoveDocument}
            onDocumentRestore={handleRestoreDocument}
            readOnly={readOnly}
          />
        </div>
        <DocumentViewer
          selectedDocument={selectedDocument}
          fields={fields}
          attachments={currentAttachments}
          documentsCount={workItem?.documents?.length || 0}
          documents={workItem?.documents || []}
          readOnly={readOnly}
          onPageCrop={onPageCrop}
          isLoadingNewData={isLoading}
          onPageStamp={onPageStamp}
          onPageApplyFilter={applyFiltersHandler}
          stampImage={workItem?.stamp}
          defaultDocumentFit={workItem?.pdfViewer?.defaultFit}
          onChangeDocument={onChangeDocument}
        />
      </div>
    </section>
  );
}
