import { Table as STable } from 'semantic-ui-react';

export default function TableSkeleton() {
  const tableHCells = () => {
    const cells = [];
    for (let i = 0; i < 6; i += 1) {
      cells.push(
        <STable.HeaderCell
          key={`skeleton_table_hc_${i}`}
          className="loading-cell table-header-cell"
        >
          <div className="loader-template" />
          <div className="head-divider" />
        </STable.HeaderCell>,
      );
    }
    return cells;
  };
  const tableBCells = () => {
    const cells = [];
    const rows = [];
    for (let i = 0; i < 6; i += 1) {
      cells.push(
        <STable.Cell key={`skeleton_table_cd_${i}`}>
          <div className="loader-template" />
        </STable.Cell>,
      );
    }
    for (let i = 0; i < 10; i += 1) {
      rows.push(
        <STable.Row key={`skeleton_table_row_${i}`}>{cells}</STable.Row>,
      );
    }
    return rows;
  };

  return (
    <>
      <div className="buttons-container loader" />
      <STable basic="very" className="loading-table">
        <STable.Header>
          <STable.Row>{tableHCells()}</STable.Row>
        </STable.Header>
        <STable.Body className="loader-table-body">{tableBCells()}</STable.Body>
      </STable>
    </>
  );
}
