import Jsona from 'jsona';

import api from '@services/axios';

const dataFormatter = new Jsona();

export const updateRules = async (id, title, value) => {
  const values = {
    type: 'rules',
    rules: { [title]: value },
  };

  const JSONApiValues = dataFormatter.serialize({
    stuff: values,
  });

  const response = await api.jsonAPI().put(`/work_items/${id}`, JSONApiValues);
  return response.data;
};
