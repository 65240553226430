import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import useApiGet from '@hooks/useApiGet';
import useBreadcrumb from '@hooks/useBreadcrumb';
import { getDocumentsTypes } from '@actions';
import api from '@services/axios';

import EmailTemplateForm from './EmailTemplateForm';

export default function EmailTemplate() {
  const documentTypes = useSelector(
    (state) => state.documentTypesReducer.documentTypes,
  ).map((doc) => ({ label: doc.label, value: doc.label }));
  const dispatch = useDispatch();
  const { id: templateId } = useParams();
  const [template, getTemplate, loading] = useApiGet(
    `api/v1/email_templates/${templateId}`,
  );
  const [statuses, setStatuses] = useState([]);
  const getStatuses = () => {
    api
      .restAPI()
      .get('/email_templates/statuses')
      .then((res) => {
        setStatuses(res.data);
      })
      .catch((err) => {
        console.error('Error on getStatuses', { error: err });
      });
  };
  const { setBreadcrumb } = useBreadcrumb();

  useEffect(() => {
    getStatuses();
    if (templateId) {
      getTemplate();
    }
    dispatch(getDocumentsTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBreadcrumb('Email Template');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  const panes = [
    {
      menuItem: 'Email Template',
      render: () => (
        <Tab.Pane attached={false}>
          <EmailTemplateForm
            template={template}
            loading={loading}
            documentTypes={documentTypes}
            statuses={statuses}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div
      className="d-flex-start"
      style={{ flexDirection: 'column', marginTop: '-14px' }}
    >
      <div
        style={{
          width: '100vw',
          background:
            'linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(34,125,152,1) 36%, rgba(2,0,36,1) 100%)',
        }}
        className="user-banner"
      />
      <Tab
        style={{ width: '100%' }}
        className="user_tabs"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </div>
  );
}
