import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';

import OutputFieldsBlock from '@components/OutputFieldsManager/components/OutputFieldsBlock';
import { setBreadcrumb } from '@actions';
import ApplicationLoader from '@ui/ApplicationLoader';
import TableButton from '@ui/Table/TableButton';
import downloadFile from '@hooks/downloadFile';

import s from './OutputFieldsManager.module.scss';
import { fetchOutputFields, saveOutputFields } from './api/api';

const OutputFieldsManager = () => {
  const dispatch = useDispatch();
  const uploadRef = useRef(null);
  const [outputFieldsData, setOutputFieldsData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const onBlockChange = (fieldField, value, blockName) => {
    const newOutputFields = { ...outputFieldsData };
    newOutputFields[blockName][fieldField] = value;
    newOutputFields[blockName].fields = newOutputFields[blockName].fields.map(
      (field) => {
        return {
          ...field,
          [fieldField]: value,
        };
      },
    );
    setOutputFieldsData(newOutputFields);
  };
  const onExportOutputFields = () => {
    downloadFile(JSON.stringify(outputFieldsData), 'outputFields', 'txt');
  };

  const onImportClick = () => {
    uploadRef.current.click();
  };

  const onImportOutputFields = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      const outputDataInJSON = reader.result;
      setOutputFieldsData(JSON.parse(outputDataInJSON));
    };
    reader.readAsText(e.target.files[0]);
  };

  const onFieldChange = (fieldField, value, fieldName, blockName) => {
    const newOutputFields = { ...outputFieldsData };
    newOutputFields[blockName].fields.map((field) => {
      if (field.name === fieldName) {
        field[fieldField] = value;
      }
      return field;
    });
    setOutputFieldsData(newOutputFields);
  };

  const onSave = () => {
    toast
      .promise(saveOutputFields(outputFieldsData), {
        loading: 'Saving...',
        success: 'Saved',
        error: 'Error',
      })
      .then((data) => {
        setOutputFieldsData(data);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchOutputFields()
      .then((data) => {
        setOutputFieldsData(data);
      })
      .finally(() => {
        setLoading(false);
      });
    dispatch(setBreadcrumb('Output Fields'));
  }, [dispatch]);

  const visibleBlocks =
    (outputFieldsData &&
      Object?.values(outputFieldsData)?.length &&
      Object.entries(outputFieldsData).map(([, field]) => field)) ||
    [];

  if (loading) {
    return <ApplicationLoader />;
  }
  return (
    <div className={s.wrapper}>
      <TableButton
        button={{
          label: 'Export',
          type: 'secondary',
          icon: 'upload',
          handleClick: onExportOutputFields,
        }}
      />
      <TableButton
        button={{
          label: 'Import',
          type: 'secondary',
          icon: 'download',
          handleClick: onImportClick,
        }}
      />
      <TableButton
        button={{
          label: 'Save',
          type: 'secondary',
          handleClick: onSave,
        }}
      />
      <div className={s.fieldsWrapper}>
        <input
          ref={uploadRef}
          type="file"
          style={{ display: 'none' }}
          id="contained-button-file"
          onChange={onImportOutputFields}
        />
        <ul className={s.fieldsContent}>
          <div className={s.fieldsHeader}>
            <div>Name</div>
            <div className={s.values}>
              <div>Visible</div>
              <div>Required</div>
            </div>
          </div>
          {visibleBlocks?.length > 0 &&
            visibleBlocks.map((field) => {
              return (
                <OutputFieldsBlock
                  field={field}
                  required={field.required}
                  key={field.name}
                  onBlockChange={onBlockChange}
                  onFieldChange={onFieldChange}
                />
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default OutputFieldsManager;
