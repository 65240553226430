import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

import useAuthToken from '@hooks/useAuthToken';
import useCurrentUser from '@hooks/useCurrentUser';
import ApplicationLoader from '@ui/ApplicationLoader';

const WithCurrentUser = ({ children }) => {
  const { currentUser, setCurrentUser, loadUsers } = useCurrentUser();
  const { t } = useTranslation();
  const { authToken } = useAuthToken();
  const location = useLocation();
  const firstLoading = useRef(true);

  useEffect(() => {
    const getUser = async () => {
      if (!currentUser && authToken) {
        await setCurrentUser();
        firstLoading.current = false;
      }
    };
    getUser();
  }, [loadUsers, setCurrentUser, currentUser, authToken]);

  useEffect(() => {
    // Check on each navigation if we are still logged in. If not - redirect to login
    if (!firstLoading.current && location.pathname !== '/login') {
      setCurrentUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!currentUser) return <ApplicationLoader text={t('AppLoader')} />;

  return <>{children}</>;
};

export default WithCurrentUser;
