import { Table } from 'semantic-ui-react';

import TableRow from './TableRow';
/* eslint react/jsx-props-no-spreading: 0 */
export default function TableBody({ show, items, ...props }) {
  if (!show) return <Table.Body />;

  return (
    <Table.Body>
      {items.map((item) => (
        <TableRow key={item.id ?? item.key} item={item} {...props} />
      ))}
    </Table.Body>
  );
}
/* eslint react/jsx-props-no-spreading: 0 */
