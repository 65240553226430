import 'semantic-ui-css/semantic.min.css';
import './stylesheets/index';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { BrowserRouter as Router } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'react-image-crop/src/ReactCrop.scss';

import ErrorScreen from '@components/ErrorScreen';
import store from '@services/store';
import api from '@services/axios';

import App from './App';
/* eslint react/jsx-props-no-spreading: 0 */
class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.error('App error:', error);
    api.jsonAPI().post('errors', {
      error: {
        message: `App error: ${error.message}, URL:${window.location.href}, ${error.stack}`,
      },
    });
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      /**
       * if we see this component, then there is an error in render
       * usually this is a data schema error that the backend sent
       */
      return <ErrorScreen />;
    }

    return this.props.children;
  }
}

function withErrorBoundary(WrappedComponent) {
  return function ErrorBoundaryWrapper(props) {
    return (
      <ErrorHandler>
        <WrappedComponent {...props} />
      </ErrorHandler>
    );
  };
}

const Main = () => (
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <Router>
        <Toaster
          toastOptions={{
            duration: 5000,
          }}
        />
        <App />
      </Router>
    </Provider>
  </DndProvider>
);

const AppWithErrorBoundary = withErrorBoundary(Main);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppWithErrorBoundary />);
/* eslint react/jsx-props-no-spreading: 0 */
