import Jsona from 'jsona';

import api from '@services/axios';

const dataFormatter = new Jsona();

export const updateAlerts = async (id, alerts) => {
  const checkedCheckboxes = alerts.reduce((base, alert) => {
    if (alert.checked) {
      base.push(alert.id);
    }
    return base;
  }, []);

  const values = {
    type: 'alerts',
    alerts: checkedCheckboxes,
  };

  const JSONApiValues = dataFormatter.serialize({
    stuff: values,
  });

  try {
    const response = await api
      .jsonAPI()
      .put(`/work_items/${id}`, JSONApiValues);
    return response?.data?.alerts;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
