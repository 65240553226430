/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';
import { Table, Icon, Ref } from 'semantic-ui-react';

import { getDeepPathValue } from '@services/general';

import ColumnAction from './ColumnAction';
import ColumnIcon from './ColumnIcon';
import TableDropdown from './TableDropdown';

export default function TableCell(props) {
  const {
    actions,
    column,
    index,
    item,
    row,
    containerRef,
    disableScrollToView,
  } = props;
  const cellRef = useRef();
  const [inViewport, setInViewport] = useState(false);

  const isSelected = props.selectedItemId === item.id;

  useEffect(() => {
    if (
      index === 0 &&
      isSelected &&
      cellRef.current &&
      !inViewport &&
      !disableScrollToView
    ) {
      cellRef.current.scrollIntoView({ block: 'center', inline: 'start' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, cellRef.current]);

  const itemValue = column.key.includes('.')
    ? getDeepPathValue(item, column.key)
    : item[column.key];

  const onCellClickHandler = () => {
    if (
      typeof props.onClick === 'function' &&
      column.config?.type !== 'actions'
    ) {
      props.onClick(item);
    }
  };

  const onIntersectionChange = React.useCallback((entries) => {
    const element = entries[0];
    setInViewport(element.isIntersecting);
  }, []);

  const observerOptions = React.useMemo(
    () => ({
      threshold: 0,
      root: containerRef?.current,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [containerRef.current],
  );

  useEffect(() => {
    if (index === 0) {
      // eslint-disable-next-line no-undef
      const observer = new IntersectionObserver(
        onIntersectionChange,
        observerOptions,
      );
      observer.observe(cellRef.current);

      return () => {
        observer.disconnect();
      };
    }
  });

  const renderContent = () => {
    if (column.config) {
      switch (column.config.type) {
        case 'icon':
          return <ColumnIcon item={item} column={column} />;
        case 'actions':
          return column.config.actions.map((action) => (
            <ColumnAction
              key={`${item[column.config.key]}_${action.type}`}
              action={action}
              column={column}
              item={item}
            />
          ));
        default: {
          break;
        }
      }
    } else if (!itemValue && itemValue !== false) return <div />;
    if (column.method) {
      return column.method(itemValue);
    }

    return (
      <>
        {' '}
        {column.icon && <Icon name={column.icon} />} {itemValue}{' '}
      </>
    );
  };

  const cellClassName = () => {
    const classes = [];

    if (typeof props.onClick === 'function') {
      classes.push('handcursor');
    }

    return classes.length > 0 ? classes.join(' ') : undefined;
  };

  return (
    <Ref innerRef={cellRef}>
      <Table.Cell
        key={`${item.id}_${column.tableKey ?? column.key}`}
        style={{ justifyContent: column.icon && 'normal' }}
        onClick={onCellClickHandler}
        className={cellClassName()}
      >
        {renderContent()}
        {index === row.length - 1 && (
          <TableDropdown
            item={item}
            actions={actions}
            containerRef={containerRef}
            setModalConfig={props.setModalConfig}
          />
        )}
      </Table.Cell>
    </Ref>
  );
}
