import api from '@services/axios';

export const fetchEmails = (workItemId) => {
  return api.restAPI().get(`emails`, {
    params: {
      'filter[work_item_id]': workItemId,
    },
  });
};

export const fetchEmailTemplates = (workItemId) => {
  return api.jsonAPI().get(`email_templates?work_item_id=${workItemId}`);
};

export const fetchEmailAddresses = (workItemId) => {
  return api.jsonAPI().get(`email_addresses?wiId=${workItemId}`);
};

export const fetchContactEmails = (clientId) => {
  return api.restAPI().get(`contact_emails?client_id=${clientId}`);
};

export const sendEmail = (formData) => {
  return api.restAPI().post('emails', formData);
};
