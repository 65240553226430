import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';

import useApiGet from '@hooks/useApiGet';
import useBreadcrumb from '@hooks/useBreadcrumb';

import UserForm from './UserForm';

const defaultProfile = `${process.env.PUBLIC_URL}/default_profile.jpg`;

export default function UsersForm() {
  const { id: userId } = useParams();
  const [user, getUser, loading] = useApiGet(`api/v1/users/${userId}`);
  const { setBreadcrumb } = useBreadcrumb();

  useEffect(() => {
    if (userId) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBreadcrumb('User Settings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const panes = [
    {
      menuItem: 'User Details',
      render: () => (
        <Tab.Pane attached={false}>
          <UserForm user={user} loading={loading} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div
      className="d-flex-start"
      style={{ flexDirection: 'column', marginTop: '-14px' }}
    >
      <div
        style={{
          width: '100vw',
          background:
            'linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(34,125,152,1) 36%, rgba(2,0,36,1) 100%)',
        }}
        className="user-banner"
      />
      <div style={{ marginTop: '-54px' }} className="profile-image-container">
        <div
          id="profile-image"
          className="ui circular image icon-button"
          style={{
            backgroundImage: `url(${user?.avatar || defaultProfile})`,
          }}
        />
        <div className="image-text">
          <div className="name">{user?.name}</div>
          <div className="email">{user?.email}</div>
        </div>
      </div>
      <Tab
        style={{ width: '100%' }}
        className="user_tabs"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </div>
  );
}
