import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import useFilters from '@hooks/useFilters';
import api from '@services/axios';
import { FILTER_TYPES } from '@components/aria/UI/Table/Filters/filterTypes';
import Table from '@components/aria/UI/Table/Table';
import downloadFile from '@hooks/downloadFile';

export default function Alerts() {
  const { t } = useTranslation();
  const [filters, updateFilters] = useFilters();
  const uploadRef = useRef(null);
  const [tableMetaData, setTableMetaData] = useState(1);
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAlerts = () => {
    setLoading(true);
    api
      .jsonAPI()
      .get('alerts', { params: filters })
      .then((res) => {
        setLoading(false);
        setAlerts(res.data);
        setTableMetaData(res.data.meta?.pagy);
      })
      .catch((err) => {
        console.error('Error on getAlerts', { error: err });
        toast.error(t('Error'));
      });
  };

  const onExportAlerts = (types) => {
    downloadFile(JSON.stringify(types), 'alerts', 'txt');
  };

  const onImportAlerts = (e) => {
    const importToast = toast.loading('Importing alerts');
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      api
        .jsonAPI()
        .post('alerts/import', { data: JSON.parse(text) })
        .then((res) => {
          setAlerts(res.data);
          toast.success('Alerts successfully imported');
        })
        .catch(() => {
          toast.error('Error while importing alerts');
        })
        .finally(() => {
          uploadRef.current.value = '';
          toast.dismiss(importToast);
        });
    };
    reader.readAsText(e.target.files[0]);
  };

  const printVisible = (bool) => {
    return bool ? 'Yes' : 'No';
  };

  const COLUMNS = [
    { name: t('Name'), key: 'name' },
    { name: t('HS name'), key: 'hsName' },
    {
      name: t('Visible'),
      key: 'visible',
      filter: FILTER_TYPES.TEXT,
      method: printVisible,
    },
  ];

  const userActions = [
    {
      link: '{id}',
      label: 'Edit',
      icon: 'edit',
    },
    {
      label: 'Delete',
      icon: 'trash',
      modal: true,
      modalConfig: {
        header: t('DeleteItem', { item: 'Alert' }),
        text: t('DeleteModalConfirmation'),
        onClick: (id) => deleteTemplate(id),
        yesButton: t('DeleteItemYes', { item: 'alert' }),
      },
    },
  ];

  useEffect(() => {
    getAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAlerts(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const tableButtons = [
    {
      label: 'Refresh',
      type: 'secondary',
      icon: 'sync',
      handleClick: () => getAlerts(filters),
    },
    {
      label: 'Import',
      type: 'secondary',
      icon: 'download',
      handleClick: () => uploadRef.current.click(),
    },
    {
      label: 'Export',
      type: 'secondary',
      icon: 'upload',
      handleClick: () => onExportAlerts(alerts),
    },
    { label: 'Create', type: 'primary', icon: 'add', link: 'new' },
  ];

  const deleteTemplate = (id) => {
    api
      .jsonAPI()
      .delete(`/alerts/${id}`)
      .then(() => {
        toast.success(t('ItemDeleted', { item: 'Template' }));
        getAlerts();
      })
      .catch((err) => {
        console.error('Error deleting the template', { err });
        toast.error(t('ItemNotDeleted', { item: 'template' }));
      });
  };

  return (
    <>
      <input
        ref={uploadRef}
        type="file"
        style={{ display: 'none' }}
        onChange={onImportAlerts}
      />
      <div className="admin-table-container">
        <Table
          columns={COLUMNS}
          items={alerts}
          totalItems={tableMetaData?.count ?? 0}
          buttons={tableButtons}
          actions={userActions}
          loading={loading}
          noItemsKey="No Templates"
          filters={filters}
          pages={tableMetaData?.last ?? 1}
          updateFilters={updateFilters}
          sessionSettings={{ name: 'ALERTS' }}
          className="adminTable"
        />
      </div>
    </>
  );
}
