import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';

import useApiGet from '@hooks/useApiGet';
import useBreadcrumb from '@hooks/useBreadcrumb';
import { getDocumentsTypes } from '@actions';

import DocumentTypesForm from './DocumentTypesForm';

export default function DocumentType() {
  const dispatch = useDispatch();
  const { id: documentTypeId } = useParams();
  const [document, getDocument, loading] = useApiGet(
    `api/v1/editable_document_types/${documentTypeId}`,
  );
  const { setBreadcrumb } = useBreadcrumb();
  useEffect(() => {}, []);
  useEffect(() => {
    if (documentTypeId) {
      getDocument();
    }
    dispatch(getDocumentsTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBreadcrumb('Document type');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const panes = [
    {
      menuItem: 'Document type',
      render: () => (
        <Tab.Pane attached={false}>
          <DocumentTypesForm documentType={document} loading={loading} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div
      className="d-flex-start"
      style={{ flexDirection: 'column', marginTop: '-14px' }}
    >
      <div
        style={{
          width: '100vw',
          background:
            'linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(34,125,152,1) 36%, rgba(2,0,36,1) 100%)',
        }}
        className="user-banner"
      />
      <Tab
        style={{ width: '100%' }}
        className="user_tabs"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </div>
  );
}
