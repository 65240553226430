import { Link } from 'react-router-dom';

import { ReactComponent as CompactLogo } from '@assets/icons/logo_compact.svg';
import { ReactComponent as SidebarLogo } from '@assets/icons/company_logo.svg';

import CollapseButton from './CollapseButton';
import { SidebarWidth } from './Sidebar';

export default function Logo({ width, toggleWidth, isSidebarDisabled }) {
  const logo = width === SidebarWidth.Thin ? <SidebarLogo /> : <CompactLogo />;

  return (
    <>
      {isSidebarDisabled ? (
        <div className="item-sb disabled logo">{logo}</div>
      ) : (
        <Link className="item-sb logo" to="/">
          {logo}
        </Link>
      )}
      <CollapseButton width={width} onClick={toggleWidth} />
    </>
  );
}
