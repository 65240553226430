import React, { useEffect } from 'react';
import { Select } from 'antd';
import { isArray } from 'lodash';

import s from '@components/GroupingNew/components/GroupingFilters/GroupingFilters.module.scss';

const GroupingFilters = ({
  batchOptions,
  emailsOptions,
  loadNumbersOptions,
  subjectLineOptions,
  updateFilters,
  filtersData,
  disableFilters,
}) => {
  const [filters, setFilters] = React.useState({
    'filter[batchId]': [],
    'filter[email]': [],
    'filter[loadNumber]': [],
    'filter[subjectLine]': [],
    ...filtersData,
  });

  const onFilterChange = (value, filterName) => {
    updateFilters({ ...filters, [filterName]: value });
    setFilters({
      ...filters,
      [filterName]: value,
    });
  };

  useEffect(() => {
    setFilters({
      ...filtersData,
    });
  }, [filtersData]);

  const onClearFilter = (name) => {
    if (filters[name]?.length) {
      updateFilters({ ...filters, [name]: [] });
    }
  };

  const renderDropdownWithClearButton = (clearFilterHandler, menu) => (
    <>
      <div className={s.selectMenu}>
        <button
          className={s.filterButton}
          type="button"
          onClick={clearFilterHandler}
        >
          Clear
        </button>
      </div>
      {menu}
    </>
  );

  return (
    <div className={s.filters}>
      <Select
        loading={disableFilters}
        optionLabelProp="label"
        maxTagCount={1}
        placeholder="Please select batch"
        className={s.filterItem}
        value={
          isArray(filters?.['filter[batchId]'])
            ? filters['filter[batchId]']
            : [parseInt(filters['filter[batchId]'], 10) || null]
        }
        mode="multiple"
        size="middle"
        onChange={(value) => onFilterChange(value, 'filter[batchId]')}
        style={{ width: '100%' }}
        options={batchOptions}
        disabled={disableFilters}
        dropdownRender={(menu) =>
          renderDropdownWithClearButton(
            () => onClearFilter('filter[batchId]'),
            menu,
          )
        }
      />
      <Select
        loading={disableFilters}
        disabled={disableFilters}
        maxTagCount={1}
        className={s.filterItem}
        mode="multiple"
        size="middle"
        placeholder="Please select email"
        value={filters?.['filter[email]'] || []}
        onChange={(value) => onFilterChange(value, 'filter[email]')}
        style={{ width: '100%' }}
        dropdownRender={(menu) =>
          renderDropdownWithClearButton(
            () => onClearFilter('filter[email]'),
            menu,
          )
        }
        options={emailsOptions}
      />
      <Select
        loading={disableFilters}
        disabled={disableFilters}
        className={s.filterItem}
        mode="multiple"
        size="middle"
        maxTagCount={1}
        placeholder="Please select load number"
        value={filters?.['filter[loadNumber]'] || []}
        onChange={(value) => onFilterChange(value, 'filter[loadNumber]')}
        style={{ width: '100%' }}
        dropdownRender={(menu) =>
          renderDropdownWithClearButton(
            () => onClearFilter('filter[loadNumber]'),
            menu,
          )
        }
        options={loadNumbersOptions}
      />
      <Select
        loading={disableFilters}
        disabled={disableFilters}
        className={s.filterItem}
        mode="multiple"
        size="middle"
        maxTagCount={1}
        placeholder="Please select subject"
        value={filters?.['filter[subjectLine]'] || []}
        onChange={(value) => onFilterChange(value, 'filter[subjectLine]')}
        style={{ width: '100%' }}
        dropdownRender={(menu) =>
          renderDropdownWithClearButton(
            () => onClearFilter('filter[subjectLine]'),
            menu,
          )
        }
        options={subjectLineOptions}
      />
    </div>
  );
};

export default GroupingFilters;
