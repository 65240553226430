import { DELETE_FILTERS, LOAD_FILTERS } from '@constants/action-types';

const initialState = { appUuid: null, filters: null };

const filtersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_FILTERS:
      return {
        appUuid: action.appUuid,
        filters: { ...action.payload },
      };
    case DELETE_FILTERS:
      return initialState;
    default:
      return state;
  }
};

export default filtersReducer;
