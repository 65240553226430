import api from '@services/axios';
import { saveFile } from '@hooks/downloadFile';

export const downloadFile = async (url, fileName) => {
  try {
    const response = await api.restAPI().get(url, {
      responseType: 'blob',
    });
    saveFile(response.data, fileName, 'pdf');
  } catch (error) {
    console.error('Failed to download file:', error);
    throw error;
  }
};
