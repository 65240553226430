import { Badge, Button, Popover, Spin, Tooltip } from 'antd';
import { Icon } from 'semantic-ui-react';
import clsx from 'clsx';
import { FilterFilled } from '@ant-design/icons';
import { useState } from 'react';

import s from '@components/Dashboard/shared/DashboardItem.module.scss';

const DashboardItem = ({
  className,
  title,
  filtersComponent,
  chartComponent,
  onClearFilters,
  onApplyFilters,
  downloadVisible = true,
  onDownloadReport,
  activeFiltersCount,
  isLoading,
  loaderWrapperStyles,
}) => {
  const [filtersActive, setFiltersActive] = useState(false);
  return (
    <div className={clsx(className, s.wrapper)}>
      <div className={s.header}>
        <h1 className={s.chartTitle}>{title}</h1>
        <div className={s.filters}>
          {downloadVisible && (
            <Tooltip title="Download Excel file">
              <Button
                type="secondary"
                onClick={() => onDownloadReport('systemThroughput')}
              >
                <Icon name="file excel" />
              </Button>
            </Tooltip>
          )}
          <Popover
            trigger={['click']}
            onOpenChange={(value) => value === false && setFiltersActive(false)}
            placement="leftTop"
            content={
              <>
                {filtersComponent}
                <br />
                <div className={s.buttons}>
                  <Button
                    type="primary"
                    onClick={() => onClearFilters('systemThroughput')}
                  >
                    Reset
                  </Button>
                  <Button type="primary" onClick={onApplyFilters}>
                    Apply
                  </Button>
                </div>
              </>
            }
            title="Filters"
          >
            <Badge size="default" count={activeFiltersCount}>
              <Tooltip title="Filters">
                <Button
                  type={filtersActive ? 'primary' : 'secondary'}
                  icon={<FilterFilled />}
                  onClick={() => setFiltersActive((prevState) => !prevState)}
                />
              </Tooltip>
            </Badge>
          </Popover>
        </div>
      </div>
      <div style={{ display: !isLoading ? 'block' : 'none' }}>
        {chartComponent}
      </div>
      <div
        className={s.loaderWrapper}
        style={{
          ...loaderWrapperStyles,
          display: isLoading ? 'flex' : 'none',
        }}
      >
        <Spin />
      </div>
    </div>
  );
};

export default DashboardItem;
