import { Icon as SuiIcon } from 'semantic-ui-react';
import axios from 'axios';
import ReactShadowRoot from 'react-shadow-root';

import s from '@components/EmailClient/components/EmailsList/EmailsList.module.scss';
import UploadedFile from '@components/EmailClient/components/UploadedFile/UploadedFile';
import { dayjsWithTimezone } from '@services/helpers';

const EmailsList = ({ emails }) => {
  if (!emails.length) return null;

  const onDownload = (e, file) => {
    e.preventDefault();
    axios({
      url: file.url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', file.name);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };
  return emails.map((email) => (
    <div key={email.id} className={s.emailWrapper}>
      <div className={s.emailHeader}>
        <div className={s.emailHeaderLeft}>
          <div className={s.emailFrom}>{email?.attributes?.from}</div>
          <div className={s.emailTo}>to: {email?.attributes?.to}</div>
          <div className={s.emailCC}>
            СС:{' '}
            {email?.attributes?.cc?.length > 0 &&
              email.attributes.cc.split(',').join(', ')}
          </div>
        </div>
        <div className={s.emailHeaderRight}>
          <div className={s.emailDate}>
            {dayjsWithTimezone(email.attributes.createdAt).format(
              'MM/DD/YYYY, HH:mm',
            )}
          </div>
          <div className={s.emailSubject}>{email.attributes.subjectLine}</div>
        </div>
      </div>
      <div>
        <ReactShadowRoot>
          {/* eslint-disable-next-line react/no-danger */}
          <div
            className={s.emailBody}
            dangerouslySetInnerHTML={{ __html: email?.attributes?.body || '' }}
          />
        </ReactShadowRoot>
      </div>

      {email.attributes.attachments.length > 0 && (
        <div className={s.filesList}>
          {email.attributes.attachments.map((file) => (
            <UploadedFile
              file={file}
              key={file.id}
              icon={
                <a
                  href={file.url}
                  target="_self"
                  download
                  onClick={(e) => onDownload(e, file)}
                  aria-label={`Download ${file.name}`}
                >
                  <SuiIcon name="download" className={s.downloadIcon} />
                </a>
              }
            />
          ))}
        </div>
      )}
    </div>
  ));
};

export default EmailsList;
