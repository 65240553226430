import api from '@services/axios';

export const getDocumentTypes = async () => {
  const url = 'document_types';
  const response = await api
    .jsonAPI()
    .get(url, { params: { 'page[number]': 0 } });
  return response.data.map((item) => ({
    label: item.name,
    value: item.id,
    visible: item.visible,
    alias: item?.alias,
  }));
};

export const generateNOA = async (workItemId) => {
  const url = `work_items/${workItemId}/make_noa`;
  const response = await api.jsonAPI().patch(url);
  return response.data;
};

export const generateInvoice = async (workItemId, documentType) => {
  const url = `work_items/${workItemId}/documents`;
  const multipartData = new FormData();
  multipartData.append('documentType', documentType);

  const response = await api.formData().post(url, multipartData);
  return response.data;
};

export const uploadDocument = async (
  workItemId,
  file,
  fileTypeLabel,
  noaToAll,
) => {
  const url = `work_items/${workItemId}/documents`;
  const multipartData = new FormData();
  multipartData.append('manuallyAddedDocument', file);
  multipartData.append('documentType', fileTypeLabel);
  if (noaToAll) {
    multipartData.append('noaToAll', noaToAll);
  }

  const response = await api.formData().post(url, multipartData);
  return response.data;
};

export const removeDocument = async (workItemId, documentId) => {
  const url = `work_items/${workItemId}/documents/${documentId}`;
  const response = await api.jsonAPI().delete(url);
  return response.data;
};

export const restoreDocument = async (workItemId, documentId) => {
  const url = `work_items/${workItemId}/documents/${documentId}/restore`;
  const response = await api.jsonAPI().patch(url);
  return response.data;
};

export const applyFilters = async (
  workItemId,
  documentId,
  method,
  value,
  pageId,
) => {
  let editData = {};

  switch (method) {
    case 'brightness':
    case 'contrast':
      editData = {
        method,
        ...(value && { percent: value * 100 }),
        page_id: pageId,
      };
      break;
    case 'rotate':
      editData = {
        method,
        ...(value && { degrees: value }),
        page_id: pageId,
      };
      break;
    default:
      throw new Error('Method handler not found');
  }

  const url = `work_items/${workItemId}/documents/${documentId}/page_edit`;
  const response = await api.jsonAPI().patch(url, editData);
  return response.data;
};

export const cropPage = async (workItemId, documentId, cropParams, pageId) => {
  const url = `work_items/${workItemId}/documents/${documentId}/page_crop`;
  const response = await api
    .jsonAPI()
    .patch(url, { crop: cropParams, page_id: pageId });
  return response.data;
};

export const stampPage = async (
  workItemId,
  documentId,
  { x1, y1, x2, y2 },
  pageId,
  stampId,
) => {
  const url = `work_items/${workItemId}/documents/${documentId}/stamp`;
  const response = await api.jsonAPI().patch(url, {
    pageId,
    stamp: { x1, y1, x2, y2 },
    stampId,
  });
  return response.data;
};
