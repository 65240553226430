import { Label, Menu, Tab } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { toast } from 'react-hot-toast';

import RuleCard from '@components/RuleCard';
import Alerts from '@components/Alerts';
import DocumentEventsForm from '@components/DocumentEventsForm';
import { setWorkItem, updateAlerts, updateRules } from '@actions';
import api from '@services/axios';

import s from './styles.module.scss';

const rulesActions = {
  fixed: 'fixed',
  ignored: 'ignored',
};

const FieldListWrapper = ({
  layouts, // eslint-disable-line no-unused-vars
  fields, // eslint-disable-line no-unused-vars
  tables, // eslint-disable-line no-unused-vars
  readOnly,
  workItemId,
}) => {
  const alerts = useSelector((state) => state.alertsReducer?.alerts);
  const checkedAlerts = useSelector(
    (state) => state.workItemReducer?.workItem?.alerts,
  );

  const dispatch = useDispatch();

  const rules = useSelector((state) => state.workItemReducer?.workItem?.rules);

  const createRulesList = (rulesList) => {
    return Object.keys(rulesList).map((rule, index) => ({
      id: index,
      title: [rule],
      key: rulesList[rule],
    }));
  };

  const rulesList = createRulesList(rules);
  const massRulesAction = async (action) => {
    const res = await toast.promise(
      api.jsonAPI().patch(`/work_items/${workItemId}/rules?state=${action}`),
      {
        loading: 'Updating rules',
        success: 'Rules updated',
        error: "Can't update rules",
      },
    );
    dispatch(setWorkItem(res.data));
  };

  const onRulesAction = async (action) => {
    await massRulesAction(action);
  };

  const panes = [
    {
      menuItem: (
        <Menu.Item key="rules">
          Rules<Label>{rulesList?.length}</Label>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane style={{ display: 'flex', flexDirection: 'column' }}>
          {rulesList?.length > 0 && (
            <div className={s.rulesActionsContainer}>
              <Button
                className={s.action}
                onClick={() => onRulesAction(rulesActions.fixed)}
                disabled={readOnly}
              >
                Fix all
              </Button>
              <Button
                className={s.action}
                onClick={() => onRulesAction(rulesActions.ignored)}
                disabled={readOnly}
              >
                Ignore all
              </Button>
            </div>
          )}
          {rulesList?.length === 0 && (
            <div className={s.noRules}>No rules found.</div>
          )}
          {rulesList?.length > 0 && (
            <div className="cardsWrapper">
              {rulesList.map((card) => (
                <RuleCard
                  title={card.title}
                  key={card.id}
                  id={card.id}
                  status={card.key}
                  onRuleChange={(newRules) => dispatch(updateRules(newRules))}
                  readOnly={readOnly}
                />
              ))}
            </div>
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Alerts',
      render: () => (
        <Tab.Pane attached={false}>
          <Alerts
            alerts={alerts}
            checkedAlerts={checkedAlerts}
            onAlertChange={(newAlerts) => dispatch(updateAlerts(newAlerts))}
            readOnly={readOnly}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Output',
      render: () => (
        <Tab.Pane>
          <DocumentEventsForm readOnly={readOnly} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className="PDFListTabs documentEventsWrapper">
      <Tab menu={{ secondary: true }} panes={panes} defaultActiveIndex={2} />
    </div>
  );
};

export default FieldListWrapper;
