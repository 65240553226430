import api from '@services/axios';

export const fetchOutputFields = async () => {
  const response = await api.restAPI().get('/hidden_ouput_fields');
  return response.data;
};

export const saveOutputFields = async (data) => {
  const response = await api
    .restAPI()
    .put('/hidden_ouput_fields/set', { data });
  return response.data;
};
