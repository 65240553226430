import { Link, useLocation } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';

export default function SidebarItem(props) {
  const location = useLocation();
  const active = () => {
    const urls = [props.url];

    if (props.altUrl) {
      if (Array.isArray(props.altUrl)) {
        props.altUrl.forEach((url) => {
          urls.push(url);
        });
      }
      urls.push(props.altUrl);
    }

    return urls.some((url) => location.pathname.match(`^${url}(\\/.*)?$`))
      ? 'selected'
      : '';
  };

  const popupStyle = () => {
    if (props.width === 'thin') return null;
    const style = {
      borderRadius: '8px',
      opacity: 0.8,
    };
    if (active() === 'selected') {
      style.marginLeft = '18px';
    }
    return style;
  };

  const renderIcon = () => {
    return <Icon name={props.icon} className="sidebar-icon" />;
  };
  return (
    <Popup
      trigger={
        props.isSidebarDisabled ? (
          <div className={`item-sb disabled ${active()}`}>
            {renderIcon()}
            <p>{props.componentName}</p>
          </div>
        ) : (
          <Link to={props.url} className={`item-sb open ${active()}`}>
            {renderIcon()}
            <p>{props.componentName}</p>
          </Link>
        )
      }
      content={
        props.isSidebarDisabled ? props.isSidebarDisabled : props.componentName
      }
      position="right center"
      pinned
      inverted
      style={popupStyle()}
      // disabled={props.width === 'thin'}
    />
  );
}
