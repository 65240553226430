import { useDispatch } from 'react-redux';
import { Input, Table } from 'semantic-ui-react';

import { updateHSField, updateHsState } from '@actions';
import useHyperscience from '@hooks/useHyperscience';

export default function HSTableCell({ cell, table }) {
  const dispatch = useDispatch();
  const { editable, selectedField, editedFields } = useHyperscience();
  const hasException = cell?.exceptions?.length > 0;
  const cellIsSelected = selectedField === cell.id;

  const cellValue = editedFields[cell.id]
    ? editedFields[cell.id].normalized
    : cell.normalized;

  const onClickHandler = () => {
    dispatch(
      updateHsState({
        field: cell.id,
        table: table.id,
      }),
    );
  };

  const onFieldChange = (evt, { value }) => {
    const auxField = {
      id: cell.id,
      type: 'table',
      table_id: table.id,
      document_table_row_id: cell.document_table_row_id,
      normalized: value,
    };
    dispatch(updateHSField(auxField));
  };

  const cellClassName = () => {
    if (hasException) return 'cell-exception';
    if (!cellValue) return 'cell-blank';
    return null;
  };

  return (
    <Table.Cell onClick={onClickHandler}>
      {cellIsSelected ? (
        <Input
          fluid
          size="mini"
          value={cellValue}
          onChange={onFieldChange}
          disabled={!editable}
        />
      ) : (
        <span className={cellClassName()}>{cellValue || 'BLANK'}</span>
      )}
    </Table.Cell>
  );
}
