import api from '@services/axios';

export const cropPage = async (workItemId, documentId, cropParams, pageId) => {
  const url = `work_items/${workItemId}/documents/${documentId}/page_crop`;
  const response = await api
    .jsonAPI()
    .patch(url, { crop: cropParams, page_id: pageId });
  return response.data;
};

export const stampPage = async (
  workItemId,
  documentId,
  { x1, y1, x2, y2 },
  pageId,
  stampId,
) => {
  const url = `work_items/${workItemId}/documents/${documentId}/stamp`;
  const response = await api.jsonAPI().patch(url, {
    pageId,
    stamp: { x1, y1, x2, y2 },
    stampId,
  });
  return response.data;
};

export const getWorkItem = async (id) => {
  const response = await api.jsonAPI().get(`/work_items/${id}`);
  return response.data;
};
