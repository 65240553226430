import { useEffect, useState } from 'react';
import Split from 'react-split';
import { Tab } from 'semantic-ui-react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import DocumentEmails from '@components/DocumentEmails';
import DocumentActivities from '@components/DocumentActivities';
import useHyperscience from '@hooks/useHyperscience';
import WorkItemDocuments from '@components/WorkItemDocuments';
import { getFieldsFromDocument } from '@services/documents';
import FieldListWrapper from '@components/aria/Application/Activity/Hyperscience/FieldListWrapper';

import DocumentViewer from './DocumentViewer/DocumentViewer';
import HSTable from './Table/HSTable';

export default function Document({
  activity,
  hsDocument,
  disableLeftEvents,
  readOnly,
}) {
  const workItemId = useSelector((state) => state.workItemReducer.workItem.id);
  const [fields, setFields] = useState([]);
  const [tables, setTables] = useState([]);
  const {
    nameFilter,
    filterExceptions,
    selectedField,
    selectedTable,
    selectField,
    selectTable,
    setEditable,
  } = useHyperscience();

  useEffect(() => {
    const isEditable = checkEditableStatus();
    setEditable(isEditable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  useEffect(() => {
    if (selectedField && !fields.some((field) => field.id === selectedField)) {
      selectField(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  useEffect(() => {
    if (selectedTable && !tables.some((table) => table.id === selectedTable)) {
      selectTable(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tables]);

  const checkEditableStatus = () => {
    const { status } = activity;
    return status !== 'Complete';
  };

  const filterTables = (tableList) => {
    if (!nameFilter && !filterExceptions) return tableList;
    return tableList.filter((table) => {
      if (
        nameFilter &&
        !table.name.toLowerCase().includes(nameFilter.toLowerCase())
      ) {
        return false;
      }
      if (filterExceptions && !tableHasExceptions(table)) {
        return false;
      }
      return true;
    });
  };

  useEffect(() => {
    if (hsDocument) {
      const fieldsFromDocument = getFieldsFromDocument(hsDocument);
      if (hsDocument.document_tables) {
        const auxTables = filterTables(hsDocument.document_tables);
        const fieldsFromTable = auxTables
          .map((table) => getFieldsFromTable(table))
          .flat();
        setFields([...fieldsFromDocument, ...fieldsFromTable]);
        setTables(auxTables);
      } else {
        setFields(fieldsFromDocument);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFilter, filterExceptions]);

  const tableHasExceptions = (tbl) => {
    return tbl.rows.some((row) => {
      return row.cells.some(
        (cell) => cell.exceptions && cell.exceptions?.length > 0,
      );
    });
  };

  const getFieldsFromTable = (table) => {
    const tableFields = table.rows.map((row) => {
      return row.cells.map((cell) => ({
        ...cell,
        type: 'cell',
        table_id: table.id,
        coordinates: cell.bounding_box
          ? {
              startX: cell.bounding_box[0],
              startY: cell.bounding_box[1],
              endX: cell.bounding_box[2],
              endY: cell.bounding_box[3],
            }
          : null,
      }));
    });
    return tableFields.flat();
  };

  const tablePresent = !!selectedTable;
  const fieldsForList = fields.filter((field) => field.type === 'field');

  return (
    <div style={{ height: '100%' }}>
      <Split
        direction="vertical"
        style={{ height: '100%' }}
        expandToMin
        gutterSize={tablePresent ? 10 : 0}
        sizes={tablePresent ? [50, 50] : [100, 0]}
        minSize={0}
      >
        <div style={{ height: '100%' }}>
          <div className="hs-data-container">
            {!disableLeftEvents && (
              <FieldListWrapper
                layouts={hsDocument.layouts}
                fields={fieldsForList}
                tables={tables}
                readOnly={readOnly}
                workItemId={workItemId}
              />
            )}
            <WorkItemDocuments fields={fields} readOnly={readOnly} />
          </div>
        </div>
        <div className="hs-table-container">
          {tablePresent && <HSTable hsDocument={hsDocument} />}
        </div>
      </Split>
    </div>
  );
}

/* eslint-disable no-unused-vars */
const DocumentViewerWrapper = ({
  hsDocument,
  fields,
  attachments,
  tabsClassName,
}) => {
  const panes = [
    {
      menuItem: 'Images',
      render: () => (
        <Tab.Pane attached={false}>
          <DocumentViewer
            hsDocument={hsDocument}
            fields={fields}
            attachments={attachments}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Emails',
      render: () => (
        <Tab.Pane attached={false}>
          <DocumentEmails />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Activities',
      render: () => (
        <Tab.Pane attached={false}>
          <DocumentActivities />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className={clsx('PDFViewerTabs', tabsClassName)}>
      <Tab menu={{ secondary: true }} panes={panes} />
    </div>
  );
};

/* eslint-disable no-unused-vars */
