import { SET_BREADCRUMB, TOGGLE_SIDE_BAR } from '@constants/action-types';

export default (state = {}, action = {}) => {
  if (action.type === SET_BREADCRUMB) {
    return { ...state, breadcrumb: action.payload };
  }
  if (action.type === TOGGLE_SIDE_BAR) {
    return { ...state, sideBar: action.payload };
  }
  return state;
};
