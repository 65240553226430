import api from '@services/axios';

export const patchAccessorial = async (workItemId, id, description, amount) => {
  const url = `/work_items/${workItemId}/accessorials/${id}`;
  const response = await api.jsonAPI().patch(url, { description, amount });
  return response.data;
};

export const deleteAccessorial = async (workItemId, id) => {
  const url = `/work_items/${workItemId}/accessorials/${id}`;
  await api.jsonAPI().delete(url);
};

export const addAccessorial = async (workItemId) => {
  const url = `/work_items/${workItemId}/accessorials/`;
  const response = await api.jsonAPI().post(url);
  return response.data;
};

export const patchOutputFields = async (
  workItemId,
  target,
  targetId,
  newFieldData,
) => {
  const url = `/work_items/${workItemId}/output_fields`;
  await api.jsonAPI().patch(url, { data: { [target]: { id: targetId } } });
  return newFieldData;
};

export const patchDebounceOutputFields = async (
  workItemId,
  fieldsType,
  target,
  debounceTarget,
) => {
  const url = `/work_items/${workItemId}/output_fields`;
  return api
    .jsonAPI()
    .patch(url, { data: { [fieldsType]: { [target]: debounceTarget } } });
};

export const getClients = async (name) => {
  const query = `?name=${name}`;
  const response = await api.jsonAPI().get(`/clients/lookup${query}`);
  return response.data;
};

export const getDebtors = async (name) => {
  const query = `?name=${name}`;
  const response = await api.jsonAPI().get(`/debtors/lookup${query}`);
  return response.data;
};

export const updatePaymentType = async (
  workItemId,
  selectedValue,
  splitValue,
) => {
  const url = `/work_items/${workItemId}/output_fields`;
  const response = await api.jsonAPI().patch(url, {
    data: {
      payment: {
        type: selectedValue,
        ...(splitValue && { split: splitValue }),
      },
    },
  });
  return response.data;
};
