import { useCallback, useState } from 'react';

import config from '@config';
import api, { handleCashAppError } from '@services/axios';

export default function useApiGet(endpoint, initialValues = null) {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(initialValues);
  const [error, setError] = useState(null);

  const setInitialConditions = () => {
    setLoading(true);
    setResponse(initialValues);
    setError(null);
  };

  const callApi = useCallback(
    (params, callback) => {
      setInitialConditions();
      api
        .jsonAPI()
        .get(`${config.api.url.http}/${endpoint}`, { params })
        .then((res) => {
          const auxResponse =
            typeof callback === 'function' ? callback(res.data) : res.data;
          setResponse(auxResponse);
        })
        .catch((err) => {
          if (endpoint.includes('external_services')) {
            handleCashAppError(err);
          }
          console.error('Error on useApiGet', { error: err });
          setError(err);
        })
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [endpoint],
  );

  return [response, callApi, loading, error];
}
