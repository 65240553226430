import clsx from 'clsx';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Spin } from 'antd';

import { AUTH_TOKEN } from '@constants';
import useAuthToken from '@hooks/useAuthToken';
import ErrorIcon from '@assets/icons/error_circle.svg';
import { ReactComponent as Logo } from '@assets/icons/login_logo.svg';

import s from './styles.module.scss';
import { loginUser } from './api/api';

const Login = ({ redirect }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authToken, cookies } = useAuthToken();

  const loginSchema = Yup.object().shape({
    password: Yup.string().required('Please enter password'),
    email: Yup.string()
      .email('Please enter a Email address')
      .required('Please enter email address'),
  });

  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      const jwt = await loginUser(values.email, values.password);
      if (jwt) {
        cookies.set(AUTH_TOKEN, jwt, { path: '/' });
        navigate(redirect || '/');
      }
    } catch (error) {
      setStatus({
        serverError: error.message,
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (authToken) return <Navigate to="/" replace />;

  return (
    <Fragment>
      <div className={s.authWrapper}>
        <div className={s.auth}>
          <div className={s.logoWrapper}>
            <Logo />
          </div>
          <div className={s.authTitle}>Welcome</div>
          <div className={s.authDetails}>Please enter your details.</div>

          <div className={s.authForm}>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={loginSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, isValid, errors, touched, status }) => {
                return (
                  <Form>
                    <div
                      className={clsx(s.fieldWrapper, {
                        [s.fieldError]: errors.email && touched.email,
                      })}
                    >
                      <Field
                        type="email"
                        name="email"
                        className={s.email}
                        placeholder={t('Enter your email')}
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className={s.errorText}
                    />
                    <div
                      className={clsx(s.fieldWrapper, s.fieldPassword, {
                        [s.fieldError]: errors.password && touched.password,
                      })}
                    >
                      <Field
                        type="password"
                        name="password"
                        className={s.password}
                        placeholder={t('Enter your password')}
                      />
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className={s.errorText}
                    />
                    <div>
                      <div className={s.serverErrorWrapper}>
                        {status?.serverError && (
                          <img
                            src={ErrorIcon}
                            alt="error icon"
                            className={s.errorIcon}
                          />
                        )}

                        <div className={s.errorText}>
                          {status?.serverError && status.serverError}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className={s.submitButton}
                        disabled={!isValid || isSubmitting}
                      >
                        {isSubmitting ? <Spin /> : t('Log in')}
                      </button>
                    </div>
                    {/* // ! WE NEED THAT IN FUTURE RELEASE */}
                    <div className={clsx(s.forgotButtonWrapper)}>
                      <Button
                        type="button"
                        className={clsx(s.forgotButton)}
                        onClick={() => navigate('/reset-password')}
                      >
                        {t('Forgot password?')}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
