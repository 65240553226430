/* eslint-disable jsx-a11y/alt-text */
/* eslint react/jsx-props-no-spreading: 0 */
import { useEffect, useRef, useState } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';
import toast from 'react-hot-toast';

import api from '@services/axios';

import Toolbar, { PDF_VIEWER_ACTIONS } from './Toolbar';
import Viewer from './Viewer';

const DEFAULT_FILTER_VALUE = 1;
export default function DocumentViewer({
  fields,
  attachments,
  selectedDocument,
  documentsCount,
  documents,
  readOnly,
  onPageCrop,
  isLoadingNewData,
  onPageStamp,
  onPageApplyFilter,
  defaultDocumentFit = 'fit',
  onChangeDocument,
}) {
  const [page, setPage] = useState(1);
  const [nPages, setNPages] = useState(1);
  const [scale, setScale] = useState(1);
  const [visualFilterValue, setVisualFilterValue] =
    useState(DEFAULT_FILTER_VALUE);
  const [editMode, setEditMode] = useState(null);
  const [prevEditMode, setPrevEditMode] = useState(null);
  const [documentReady, setDocumentReady] = useState(false);
  const [dimensions, setDimensions] = useState();
  const [selectedStamp, setSelectedStamp] = useState(null);
  const [stamps, setStamps] = useState([]);
  const imgRef = useRef();
  const containerRef = useRef();
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:

  useEffect(() => {
    api
      .jsonAPI()
      .get('stamps')
      .then((res) => {
        setStamps(res.data);
      })
      .catch((err) => {
        console.error('Error on getAlerts', { error: err });
        toast.error('Error');
      });
    window.addEventListener('resize', calculateDimensions);
    return () => {
      window.removeEventListener('resize', calculateDimensions);
    };
  }, []);

  useEffect(() => {
    if (attachments.length === 0 && selectedDocument === null) {
      setPage(0);
      setNPages(0);
    }
    if (selectedDocument !== null && attachments.length === 0) {
      setPage(0);
      setNPages(0);
    } else {
      const pages = attachments.length > 0 ? attachments.length : 1;
      setPage(editMode ? page : 1);
      setNPages(pages);
    }
    setEditMode(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  const getCurrentPageId = (pag) => {
    return documents?.find((doc) => doc.id === selectedDocument)?.pages[pag - 1]
      ?.id;
  };

  const getAttachmentUrl = (currentPageId) => {
    return attachments.find((attachment) => {
      return parseInt(attachment?.id, 10) === currentPageId;
    })?.corrected_image_url;
  };

  const file = getAttachmentUrl(getCurrentPageId(page));

  useEffect(() => {
    setDocumentReady(false);
  }, [file]);

  useEffect(() => {
    if (imgWidth) {
      calculateDimensions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  const imgWidth = imgRef.current?.naturalWidth
    ? imgRef.current.naturalWidth * scale
    : 0;
  const imgSmall =
    imgRef.current?.offsetWidth <= containerRef.current?.offsetWidth;
  const onImageLoad = ({ target: img }) => {
    setTimeout(() => {
      setDocumentReady(true);
      if (
        prevEditMode === PDF_VIEWER_ACTIONS.zoomByLasso &&
        editMode === null
      ) {
        return;
      }
      const { naturalWidth, naturalHeight } = img;
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;
      const bestScaleByHeight = containerHeight / naturalHeight;
      const bestScaleByWidth = containerWidth / naturalWidth;
      const bestFit =
        naturalWidth > naturalHeight ? bestScaleByWidth : bestScaleByHeight;
      if (
        editMode !== PDF_VIEWER_ACTIONS.contrast &&
        editMode !== PDF_VIEWER_ACTIONS.brightness
      ) {
        switch (defaultDocumentFit) {
          case 'fit': {
            setScale(bestFit);
            break;
          }
          case 'width': {
            setScale(bestScaleByWidth);
            break;
          }
          case 'height': {
            setScale(bestScaleByHeight);
            break;
          }
          default: {
            setScale(bestFit);
            break;
          }
        }
      }
    }, 50);
  };

  const calculateDimensions = () => {
    if (imgRef.current) {
      const { width, height } = imgRef.current.getBoundingClientRect();
      setDimensions({ width, height });
    }
  };

  const onCancelCrop = () => {
    setEditMode(null);
  };
  const onCancelStamping = () => {
    setEditMode(null);
    setSelectedStamp(null);
  };

  const onStampSelect = (stamp) => {
    setSelectedStamp(stamp);
    setEditMode(PDF_VIEWER_ACTIONS.stamp);
  };

  const onToolbarAction = (action) => {
    if (editMode === action) {
      setEditMode(null);
      return;
    }
    switch (action) {
      case PDF_VIEWER_ACTIONS.crop:
        setEditMode(PDF_VIEWER_ACTIONS.crop);
        break;
      case PDF_VIEWER_ACTIONS.stamp:
        setEditMode(PDF_VIEWER_ACTIONS.stamp);
        break;
      case PDF_VIEWER_ACTIONS.contrast:
        setEditMode(PDF_VIEWER_ACTIONS.contrast);
        break;
      case PDF_VIEWER_ACTIONS.brightness:
        setEditMode(PDF_VIEWER_ACTIONS.brightness);
        break;
      case PDF_VIEWER_ACTIONS.rotate: {
        setEditMode(PDF_VIEWER_ACTIONS.rotate);
        const currentPageId = getCurrentPageId(page);
        onPageApplyFilter({
          method: 'rotate',
          value: 90,
          pageId: currentPageId,
        });
        break;
      }
      case PDF_VIEWER_ACTIONS.zoomByLasso: {
        setEditMode(PDF_VIEWER_ACTIONS.zoomByLasso);
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.log(`Unknown action: ${action}`);
        break;
      }
    }
  };

  const onCrop = (cropParams) => {
    const currentPageId = getCurrentPageId(page);
    onPageCrop(cropParams, currentPageId);
  };
  const onAreaSelectedByLasso = (area) => {
    const selectedAreaWidth = area.width / scale;
    setScale(dimensions.width / selectedAreaWidth);
    setTimeout(() => {
      ref.current.scrollTo(
        ((area.x / scale) * dimensions.width) / selectedAreaWidth,
        ((area.y / scale) * dimensions.width) / selectedAreaWidth,
      );
      setEditMode(null);
      setPrevEditMode(PDF_VIEWER_ACTIONS.zoomByLasso);
    }, 0);
  };

  const onFilter = ({ method, value }) => {
    const pageId = getCurrentPageId(page);
    onPageApplyFilter({ method, value, pageId });
  };

  const onStamp = (stampParams) => {
    const currentPageId = getCurrentPageId(page);
    onPageStamp(
      {
        x1: stampParams.position.x1 / scale,
        y1: stampParams.position.y1 / scale,
        x2: stampParams.position.x2 / scale,
        y2: stampParams.position.y2 / scale,
      },
      currentPageId,
      selectedStamp?.id,
    );
  };

  const subContainerStyles = {
    width: imgWidth ? `${imgWidth}px` : '100%',
    height: !imgWidth || !documentReady ? '100%' : undefined,
    position: 'absolute',
    left: imgSmall ? '50%' : '0',
    transform: imgSmall ? 'translate(-50%, 0%) rotate(0deg)' : null,
  };

  const currentPageId = getCurrentPageId(page);
  const currentPageFields = fields.filter(
    (field) => field.page_id === currentPageId,
  );
  const onPageChange = (delta) => {
    const docsForNavigation = documents.filter((doc) => !doc.discarded);

    const currentDocumentOrder = docsForNavigation.findIndex(
      (doc) => doc.id === selectedDocument,
    );

    const nextPageCount = page + delta;

    if (nextPageCount === nPages + 1) {
      const nextDocumentOrder = currentDocumentOrder + 1;
      onChangeDocument(
        docsForNavigation[
          nextDocumentOrder < docsForNavigation.length ? nextDocumentOrder : 0
        ].id,
      );
    } else if (nextPageCount === 0) {
      const nextDocumentOrder = currentDocumentOrder - 1;
      onChangeDocument(
        docsForNavigation[
          nextDocumentOrder < 0
            ? docsForNavigation.length - 1
            : nextDocumentOrder
        ].id,
      );
    } else {
      setPage((prevPage) => prevPage + delta);
    }
  };

  useEffect(() => {
    setVisualFilterValue(DEFAULT_FILTER_VALUE);
  }, [editMode]);
  return (
    <div className="document-viewer">
      <Toolbar
        page={page}
        nPages={nPages}
        scale={scale}
        onPageChange={onPageChange}
        setScale={setScale}
        className="no-shadow"
        containerRef={containerRef}
        imgRef={imgRef?.current || null}
        editMode={editMode}
        setEditMode={onToolbarAction}
        readOnly={readOnly}
        onFilterValueChange={setVisualFilterValue}
        visualFilterValue={visualFilterValue}
        onFilterSave={onFilter}
        file={file}
        stamps={stamps}
        onStampSelect={onStampSelect}
        selectedStamp={selectedStamp}
      />
      <div className="scrollable-document" ref={ref} {...events}>
        <div className="document-container" ref={containerRef}>
          <div style={subContainerStyles}>
            <Viewer
              file={file}
              scale={scale}
              documentReady={documentReady}
              imgRef={imgRef}
              onImageLoad={onImageLoad}
              dimensions={dimensions}
              currentPageFields={currentPageFields}
              documentsCount={documentsCount}
              editMode={editMode}
              onCancelCrop={onCancelCrop}
              onCrop={onCrop}
              isLoadingNewData={isLoadingNewData}
              onStamp={onStamp}
              onCancelStamping={onCancelStamping}
              appliedFilterValue={visualFilterValue}
              stampImage={selectedStamp?.attachment?.url}
              onSelectAreaByLasso={onAreaSelectedByLasso}
              readOnly={readOnly}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
/* eslint react/jsx-props-no-spreading: 0 */
