import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import List from './List';
/* eslint react/jsx-props-no-spreading: 0 */
export default function Sorter(props) {
  return (
    <DndProvider backend={HTML5Backend}>
      <List {...props} />
    </DndProvider>
  );
}
/* eslint react/jsx-props-no-spreading: 0 */
