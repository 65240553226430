import api from '@services/axios';

export const fetchUpdatedDocuments = async (updatedWID) => {
  const response = await api.jsonAPI().get(`/work_items/${updatedWID}`);
  return response.data.documents;
};

export const saveEmail = async (email, clientId) => {
  const data = {
    email: {
      clientId,
      email,
    },
  };
  const response = await api.jsonAPI().post('contact_emails', data);
  return response.data;
};

export const unlockWorkItem = async (id) => {
  const response = await api.restAPI().patch(`work_items/${id}/force_lock`);
  return response.data;
};

export const removeOrRestoreWorkItem = async (workItemId, discardedAt) => {
  if (discardedAt) {
    const response = await api
      .jsonAPI()
      .patch(`work_items/${workItemId}/restore_destroy`);
    return response.data;
  }
  await api.jsonAPI().delete(`work_items/${workItemId}`);
  return {};
};

export const updateTags = async (workItemId, tags) => {
  const response = await api.jsonAPI().patch(`work_items/${workItemId}/tags`, {
    data: { tags },
  });
  return response.data.tags;
};

export const changeWorkItem = async (id, type, filters) => {
  const response = await api.restAPI().get(`/work_items/${type}`, {
    params: { id, ...filters },
  });
  return response.data;
};

export const fetchTags = async () => {
  const response = await api.jsonAPI().get('tags');
  return response.data.map((item) => item.name) || [];
};

export const fetchWorkItem = async (workItemId) => {
  const response = await api.jsonAPI().get(`/work_items/${workItemId}`);
  return response.data;
};

export const fetchEmails = async (clientId) => {
  const response = await api
    .restAPI()
    .get(`contact_emails?client_id=${clientId}`);
  return response.data.data.map((email) => ({
    key: email.id,
    email: email.attributes.email,
  }));
};

export const fetchAlerts = async () => {
  const response = await api.restAPI().get('/work_items/alerts');
  return response.data?.data;
};
