import api from '@services/axios';
import { getFiltersOptions } from '@components/GroupingNew/helpers';

export const closeGrouping = async (id) => {
  try {
    await api.jsonAPI().post(`/work_items/${id}/close_grouping`);
  } catch (err) {
    console.error('Error closing grouping:', err);
  }
};

export const fetchFiltersData = async () => {
  try {
    const response = await api.restAPI().get(`/work_items/grouping_filtes`);
    return getFiltersOptions(response.data);
  } catch (err) {
    console.error('Error fetching filters data:', err);
    throw err;
  }
};
