/* eslint-disable camelcase */
/* eslint-disable no-undef */
import { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

// import useCurrentUser from '@hooks/useCurrentUser';

export default function Widget({ service_url, submission_id, closePoup }) {
  const [widget, setWidget] = useState();
  // const { currentUser } = useCurrentUser();
  const { t } = useTranslation();
  const hsInstance = service_url ?? null;

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `${hsInstance}static/widget/main.js`;
    script.async = true;

    document.body.appendChild(script);
    const link = document.createElement('link');

    link.href = `${hsInstance}static/widget/main.css`;
    link.rel = 'stylesheet';
    document.body.appendChild(link);
    setWidget({ script, link });

    const collapseButton = document.getElementsByClassName(
      'collapse-menu-button',
    )[0];
    if (collapseButton.getAttribute('class').indexOf('very') < 0) {
      collapseButton.click();
    }

    return () => {
      HyperscienceWidget.destroy('hs-widget');
      document.body.removeChild(script);
      document.body.removeChild(link);
      setWidget(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (widget) {
      showWidget();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget]);

  // const getUserCredentials = () => {
  //   const user_alias = !isEmpty(currentUser.authTokens)
  //     ? currentUser.authTokens[0].user_alias
  //     : null;
  //   const token = !isEmpty(currentUser.authTokens)
  //     ? currentUser.authTokens[0].token
  //     : null;
  //   return { user_alias, token };
  // };

  const showWidget = () => {
    // get token from HS
    // const { user_alias, token } = getUserCredentials();
    const user_alias = 'hsuser';
    const token = 'bc76f310bcfb1f9a657dae1c25721d1275577dd1';
    if ([hsInstance, user_alias, token].some((attribute) => !attribute)) {
      toast.error(t('MissingCredentialsError'));
    } else {
      axios
        .get(`${hsInstance}profile/user/${user_alias}/token`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => initHSWidget(response.data))
        .catch((error) => {
          toast.error(t('WidgetLoadingError'));
          console.error('Error fetching token from HyperScience:', error);
        });
    }
  };

  const hsCallback = (messageType, info) => {
    if (messageType === HyperscienceWidget.MessageType.ERROR) {
      console.error(info.message);
    } else if (messageType === HyperscienceWidget.MessageType.COMPLETE) {
      hsSuccessCallback();
    }
  };

  const hsSuccessCallback = async () => {
    await HyperscienceWidget.destroy('hs-widget');
    setTimeout(() => {
      closePoup(false);
    }, 3000);
  };

  const initHSWidget = (apiToken) => {
    HyperscienceWidget.init('hs-widget', {
      url: hsInstance,
      api_token: apiToken,
      submission_id,
      callback: hsCallback,
    });
  };

  return <div id="hs-widget" />;
}
