import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { cloneDeep } from 'lodash';
import { Checkbox, Icon } from 'semantic-ui-react';

export default function SorterItem({
  element,
  index,
  reorderItems,
  items,
  ...props
}) {
  const ref = useRef(null);

  const handleReorder = (oldIndex, newIndex) => {
    reorderItems((prevSections) => {
      const auxItems = cloneDeep(prevSections);
      const sectionToMove = auxItems[oldIndex];
      auxItems.splice(oldIndex, 1);
      auxItems.splice(newIndex, 0, sectionToMove);
      return auxItems;
    });
  };

  const handleDragEnd = () => {
    if (props.userSettingsHandler) {
      const order = items.map((item) => item.id);
      props.userSettingsHandler(order);
    }
  };

  const [, drop] = useDrop(
    () => ({
      accept: 'Section',
      hover: (draggedItem, monitor) => {
        if (!ref.current) return;

        const dragIndex = draggedItem.index;
        const dropIndex = index;

        // Don't replace item with itself
        if (dragIndex === dropIndex) return;

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < dropIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > dropIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        handleReorder(dragIndex, dropIndex);

        draggedItem.index = dropIndex;
      },
    }),
    [index],
  );

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'Section',
      item: () => {
        return { element, index };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: () => handleDragEnd(),
    }),
    [index],
  );

  drag(drop(ref));

  const itemClassName = () => {
    const classes = ['sorter-item'];

    // if (props.toggle) {
    //   classes.push('toggle')
    // }

    return classes.join(' ');
  };

  const itemName = () => {
    if (element.name === '') {
      return element?.sortName;
    }
    return element.name;
  };

  return (
    <li
      ref={ref}
      key={`sorter_${element.key}`}
      className={itemClassName()}
      style={{
        background: isDragging ? 'rgb(240, 238, 240)' : undefined,
        cursor: isDragging ? 'grabbing' : undefined,
        opacity: isDragging ? 0.4 : 1,
        justifyContent: props.toggleItem ? 'space-between' : 'flex-start',
      }}
    >
      <div>
        <Icon name="bars" className="sorter-bar" />
        {itemName()}
      </div>
      {props.toggleItem && (
        <Checkbox
          onClick={() => props.toggleItem(element, index)}
          checked={element.show !== false}
          toggle
        />
      )}
    </li>
  );
}
