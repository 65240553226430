import { useState, useEffect, useRef } from 'react';
import { Button } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';

import {
  // updateHSField,
  updateHsState,
} from '@actions';

export default function BoxInput({
  field,
  containerWidth,
  containerHeight,
  onPasteClick,
  readOnly,
}) {
  const dispatch = useDispatch();
  const { editedFields, editable } = useSelector(
    (state) => state.hyperscienceReducer,
  );
  const selectedOutputField = useSelector(
    (state) => state?.workItemReducer?.editedField?.field,
  );
  const modalRef = useRef();
  const [modalPosition, setModalPosition] = useState(null);
  const fieldName = field.name ?? field.column_name;

  useEffect(() => {
    if (modalRef.current && containerWidth) {
      const auxPosition = containerWidth / 2 - modalRef.current.offsetWidth / 2;
      setModalPosition(auxPosition > 0 ? auxPosition : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalRef.current]);

  const getFieldValue = () => {
    if (editedFields[field.id]) return editedFields[field.id].normalized;
    return field.transcription
      ? field.transcription.normalized
      : field.normalized;
  };

  const handleFieldChange = () => {
    // const auxField = {
    //   id: field.id,
    //   type: field.transcription ? 'field' : 'table',
    //   table_id: field.table_id ?? null,
    //   row_id: field.document_table_row_id ?? null,
    //   normalized: evt.target.value,
    //   document_id: field.document_id ?? null,
    // };
    // dispatch(updateHSField(auxField));
  };

  const onCancelHandler = () => {
    dispatch(updateHsState({ field: null }));
  };

  return (
    <div
      ref={modalRef}
      className="box-input"
      style={{
        position: 'absolute',
        left: `${modalPosition}px`,
        top: `${containerHeight + 2}px`,
        display: modalPosition === null ? 'none' : 'initial',
      }}
    >
      <div
        style={{
          color: 'rgb(79, 79, 79)',
          padding: '4px',
          fontSize: '12px',
          fontWeight: '700',
        }}
      >
        {fieldName}
      </div>
      <input
        value={getFieldValue()}
        onChange={handleFieldChange}
        disabled={!editable}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '6px',
          gap: '6px',
        }}
      >
        {selectedOutputField?.length && !readOnly && (
          <Button circular icon="times" size="small" onClick={onPasteClick}>
            <ArrowLeftOutlined />
          </Button>
        )}

        <Button circular icon="times" size="small" onClick={onCancelHandler} />
      </div>
    </div>
  );
}
