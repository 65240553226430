import { applyMiddleware, compose, createStore } from 'redux';
import { logger } from 'redux-logger';
import { thunk } from 'redux-thunk';

import rootReducer from '@reducers';

const middlewares = [thunk];
const isProd = process.env.NODE_ENV !== 'production';

if (!isProd && process.env.TURN_ON_LOGGER) {
  middlewares.push(logger);
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewareInit = composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(rootReducer, middlewareInit);

export default store;
