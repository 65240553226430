import { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Icon as SuiIcon } from 'semantic-ui-react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';

import s from '@components/DocumentCard/DocumentCard.module.scss';
import config from '@config';
import { cookies } from '@constants/cookies';
import { AUTH_TOKEN } from '@constants';
import DocumentPage from '@components/DocumentPage/DocumentPage';
import NewModal from '@components/Modal/NewModal';

const getDocumentTypeOptions = (documentTypes, name) => {
  const documentTypesOptions = [...documentTypes].filter((doc) => doc.visible);
  if (!documentTypesOptions.some((option) => option.label === name)) {
    documentTypesOptions.push({
      label: name,
      value: documentTypesOptions.length,
    });
  }
  return documentTypesOptions;
};

const DocumentCard = ({
  document,
  onPageDrop,
  wiId,
  onDownloadDocument,
  from,
  onPageClick,
  defaultOpen,
  onDocumentTypeChange,
  readOnly,
  onRemove,
  onSelectPage,
  selectedPages,
}) => {
  const [open, setOpen] = useState(defaultOpen || false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const baseUrl = config.api.url.http || '';
  const accessToken = cookies.get(AUTH_TOKEN);
  const images =
    document.pages.length &&
    document.pages.map((page) => {
      if (page?.corrected_image_url?.includes('https')) {
        return { ...page, src: page.corrected_image_url };
      }
      return {
        ...page,
        src: `${baseUrl}/api/v1/work_items/${wiId}/attachments?url=${page?.corrected_image_url}&jwt=${accessToken}`,
      };
    });
  const documentTypes = useSelector(
    (state) => state.documentTypesReducer.documentTypes,
  );
  const documentTypesOptions = getDocumentTypeOptions(
    documentTypes,
    document.layout_name,
  );

  const [, drop] = useDrop(
    () => ({
      accept: readOnly ? '' : 'page',
      drop: (item, monitor) => {
        const didDrop = monitor.didDrop();
        if (didDrop) {
          return;
        }
        onPageDrop({
          newDocumentId: document.id,
          oldDocumentId: item.documentId,
          newWorkItemId: wiId,
          oldWorkItemId: item.wiId,
          pageIds: item.selectedPages.length ? item.selectedPages : [item.id],
          from: item.from,
          to: from,
        });
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [document],
  );

  const [, drag] = useDrag(() => ({
    type: 'document',
    item: { id: document.id, wiId, from },
    canDrag: !readOnly,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const toggleCardClick = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onRemoveClick = () => {
    onRemove(document.id, from, wiId);
    setRemoveModalVisible(false);
  };

  return drag(
    drop(
      <div className={s.card} onClick={toggleCardClick} id="document">
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <NewModal
            title="Do you want to delete document?"
            onCancel={() => setRemoveModalVisible(false)}
            visible={removeModalVisible}
            onCrossClick={() => setRemoveModalVisible(false)}
            onSubmit={onRemoveClick}
          >
            This will delete the{' '}
            <b>{`${document.layout_name} #${document.id} `}</b>
            document
          </NewModal>
        </div>
        <div className={s.header}>
          <div>
            <Select
              disabled={readOnly}
              value={document.layout_name}
              bordered={false}
              className={s.title}
              onChange={(_, option) =>
                onDocumentTypeChange({
                  selectedType: option,
                  documentId: document.id,
                  wiId,
                  from,
                })
              }
              options={documentTypesOptions}
              onClick={(evt) => {
                evt.stopPropagation();
              }}
              suffixIcon={
                <SuiIcon name="caret down" className={s.headerCaret} />
              }
              popupMatchSelectWidth={200}
            />
            #{document?.id}
          </div>
          <div className={s.actions}>
            <div>
              <SuiIcon
                name="download"
                className={s.downloadIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  onDownloadDocument({ wiId, document });
                }}
              />
            </div>
            <div>
              <SuiIcon
                name="trash"
                className={s.deleteIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  setRemoveModalVisible(true);
                }}
              />
            </div>
            <div style={{ color: '#808080' }}>
              {document?.pages?.length} pages
            </div>
            <div>
              {open ? (
                <CaretRightOutlined
                  style={{ color: '#918f8c' }}
                  onClick={() => setOpen(!open)}
                />
              ) : (
                <CaretDownOutlined
                  style={{ color: '#918f8c' }}
                  onClick={() => setOpen(!open)}
                />
              )}
            </div>
          </div>
        </div>
        {open && (
          <div className={s.pagesWrapper}>
            {images?.length &&
              images.map((page, index) => (
                <DocumentPage
                  onPageClick={onPageClick}
                  from={from}
                  page={page}
                  count={index + 1}
                  key={page.id}
                  documentId={document.id}
                  wiId={wiId}
                  readOnly={readOnly}
                  onPageDrop={onPageDrop}
                  onSelectPage={onSelectPage}
                  selectedPages={selectedPages}
                />
              ))}
          </div>
        )}
      </div>,
    ),
  );
};

export default DocumentCard;
