import { Modal } from 'antd';
import { Button } from 'semantic-ui-react';

import s from '@components/Modal/NewModel.module.scss';

const NewModal = ({
  title,
  visible,
  onCrossClick,
  onCancel,
  onSubmit,
  children,
  submitButtonText = 'Submit',
  cancelButtonText = 'Skip, Delete',
  closable = true,
  onClick,
  maskClosable,
  cancelButtonVisible = true,
  width = '420px',
  footer,
}) => {
  return (
    <Modal
      width={width}
      onClick={onClick}
      open={visible}
      onCancel={onCrossClick}
      centered
      destroyOnClose
      closable={closable}
      title={title}
      maskClosable={maskClosable}
      okText="text"
      footer={
        footer !== null && [
          cancelButtonVisible && (
            <Button
              key="skip"
              onClick={onCancel}
              style={{
                backgroundColor: '#f0f0f0',
                color: '#555',
                marginRight: '12px',
              }}
            >
              {cancelButtonText}
            </Button>
          ),
          <Button
            key="continue"
            primary
            className={s.submitButton}
            onClick={onSubmit}
            style={{
              backgroundColor: '#1890ff',
              color: '#fff',
              fontWeight: '500',
            }}
          >
            {submitButtonText}
          </Button>,
        ]
      }
    >
      <p style={{ fontSize: '14px', color: '#666', lineHeight: '1.6' }}>
        {children}
      </p>
    </Modal>
  );
};

export default NewModal;
