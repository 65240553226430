import { useEffect, useRef, useState } from 'react';
import { Select, Space, Tag, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const TagsEditor = ({ value, onChange, availableTags, readOnly }) => {
  const [tags, setTags] = useState(value || []);
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setTags(value);
  }, [value]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    onChange(newTags);
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (_, selectedOption) => {
    if (selectedOption.label && tags.indexOf(selectedOption.label) === -1) {
      setTags([...tags, selectedOption.label]);
      onChange([...tags, selectedOption.label]);
    }
    setInputVisible(false);
  };
  const handleInputConfirm = () => {
    setInputVisible(false);
  };
  const tagInputStyle = {
    width: 100,
    verticalAlign: 'top',
  };
  return (
    <Space size={[0, 8]} wrap>
      <Space size={[0, 8]} wrap style={{ maxWidth: '600px' }}>
        {tags?.length > 0 &&
          tags
            .sort((a, b) => a.localeCompare(b))
            .map((tag) => {
              const isLongTag = tag?.length > 20;
              const tagElem = (
                <Tag
                  key={tag}
                  closable={!readOnly}
                  style={{
                    userSelect: 'none',
                  }}
                  onClose={() => handleClose(tag)}
                >
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
      </Space>
      {inputVisible ? (
        <Select
          open
          options={availableTags
            ?.filter((option) => tags.indexOf(option) === -1)
            ?.sort((a, b) => a.localeCompare(b))
            ?.map((tag) => ({
              label: tag,
              value: tag,
            }))}
          ref={inputRef}
          type="text"
          size="small"
          style={tagInputStyle}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
        />
      ) : (
        <Tag onClick={!readOnly ? showInput : null}>
          <PlusOutlined /> New Tag
        </Tag>
      )}
    </Space>
  );
};

export default TagsEditor;
