import Sidebar from '@components/aria/Sidebar/Sidebar';
import WithCurrentUser from '@services/WithCurrentUser';
import GroupingNew from '@components/GroupingNew/GroupingNew';

const DocumentPage = (props) => {
  return (
    <WithCurrentUser>
      <Sidebar isSidebarDisabled={props.hasUnsavedChanges}>
        <GroupingNew
          hasUnsavedChanges={props.hasUnsavedChanges}
          setHasUnsavedChanges={props.setHasUnsavedChanges}
        />
      </Sidebar>
    </WithCurrentUser>
  );
};

export default DocumentPage;
