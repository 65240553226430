import { Radio } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

import s from './styles.module.scss';
import { updateRules } from './api/api';

const RuleCard = ({ title, id: itemId, status, onRuleChange, readOnly }) => {
  const [radioValue, setRadioValue] = useState(status);
  const { id } = useParams();

  const handleChange = async (e, data) => {
    const newValue = data.value;
    setRadioValue(newValue);

    try {
      const result = await updateRules(id, title, newValue);
      onRuleChange(result?.rules);
    } catch (err) {
      console.error('Error updating rules:', err);
    }
  };

  useEffect(() => {
    setRadioValue(status);
  }, [status]);

  return (
    <>
      <div className={clsx(s.cardWrapper, 'rulesWrapper')}>
        <div className={s.cardTitle}>{title}</div>
        <div className={s.cardStatuses}>
          {/* <div className={s.statusReject}>Reject</div> */}
          <div className={clsx(s.statusIgnore, 'statusIgnore')}>
            <Radio
              label="Ignore"
              name={`radioGroup-${itemId}`}
              checked={radioValue === 'ignored'}
              onChange={handleChange}
              value="ignored"
              disabled={readOnly}
            />
          </div>
          <div className={clsx(s.statusFix, 'statusFix')}>
            <Radio
              label="Fix"
              name={`radioGroup-${itemId}`}
              checked={radioValue === 'fixed'}
              onChange={handleChange}
              value="fixed"
              disabled={readOnly}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RuleCard;
