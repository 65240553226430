import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useFilters from '@hooks/useFilters';
import api from '@services/axios';
import { FILTER_TYPES } from '@components/aria/UI/Table/Filters/filterTypes';
import Table from '@components/aria/UI/Table/Table';
import { getDocumentsTypes, setBreadcrumb } from '@actions';
import { WORK_ITEM_DISPLAY_STATUSES } from '@constants';

export default function EmailTemplates() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filters, updateFilters] = useFilters();
  const [tableMetaData, setTableMetaData] = useState(1);
  const [templates, setTemplates] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const documentTypes = useSelector(
    (state) => state.documentTypesReducer.documentTypes,
  )
    ?.filter((doc) => doc.visible)
    .map((doc) => ({
      value: doc.label,
      label: doc.label,
      visible: doc.visible,
    }));
  const getStatuses = () => {
    api
      .restAPI()
      .get('/work_items/status')
      .then((res) => {
        setStatuses(
          Object.keys(res.data.data)
            .map((status) => {
              return {
                value: status,
                label: WORK_ITEM_DISPLAY_STATUSES[status],
              };
            })
            .filter((status) => status.label),
        );
      })
      .catch((err) => {
        console.error('Error on getStatuses', { error: err });
        toast.error(t('Error'));
      });
  };
  const getTemplates = () => {
    setLoading(true);
    api
      .jsonAPI()
      .get('email_templates', { params: filters })
      .then((res) => {
        setLoading(false);
        setTemplates(res.data);
        setTableMetaData(res.data.meta?.pagy);
      })
      .catch((err) => {
        console.error('Error on getTemplates', { error: err });
        toast.error(t('Error'));
      });
  };

  const printMerge = (merge) => {
    return merge ? 'Yes' : 'No';
  };
  const printDocumentTypes = (docTypes) => {
    return docTypes.join(', ') || 'N/A';
  };

  const printStatus = (status) => {
    return WORK_ITEM_DISPLAY_STATUSES[status];
  };

  const COLUMNS = [
    { name: t('Title'), key: 'title' },
    { name: t('Subject'), key: 'subject' },
    { name: t('Email body'), key: 'template' },
    {
      name: t('To merge documents'),
      key: 'toMergeDocuments',
      filter: FILTER_TYPES.SELECT,
      options: [
        { label: 'Yes', value: 'true', visible: true },
        { label: 'No', value: 'false', visible: true },
      ],
      method: printMerge,
    },
    {
      name: t('Document Types'),
      key: 'documentTypes',
      filter: FILTER_TYPES.SELECT,
      options: documentTypes,
      method: printDocumentTypes,
    },
    {
      name: t('Next status'),
      key: 'nextStatus',
      filter: FILTER_TYPES.SELECT,
      options: statuses,
      method: printStatus,
    },
  ];
  const userActions = [
    {
      link: '{id}',
      label: 'Edit',
      icon: 'edit',
    },
    {
      label: 'Delete',
      icon: 'trash',
      modal: true,
      modalConfig: {
        header: t('DeleteItem', { item: 'Template' }),
        text: t('DeleteModalConfirmation'),
        onClick: (id) => deleteTemplate(id),
        yesButton: t('DeleteItemYes', { item: 'template' }),
      },
    },
  ];

  useEffect(() => {
    getTemplates();
    getStatuses();
    dispatch(getDocumentsTypes());
    dispatch(setBreadcrumb('Email Templates'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTemplates(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const tableButtons = [
    {
      label: 'Refresh',
      type: 'secondary',
      icon: 'sync',
      handleClick: () => getTemplates(filters),
    },
    { label: 'Create', type: 'primary', icon: 'add', link: 'new' },
  ];

  const deleteTemplate = (id) => {
    api
      .jsonAPI()
      .delete(`/email_templates/${id}`)
      .then(() => {
        toast.success(t('ItemDeleted', { item: 'Template' }));
        getTemplates();
      })
      .catch((err) => {
        console.error('Error deleting the template', { err });
        toast.error(t('ItemNotDeleted', { item: 'template' }));
      });
  };

  return (
    <div className="admin-table-container">
      <Table
        columns={COLUMNS}
        items={templates}
        totalItems={tableMetaData?.count ?? 0}
        buttons={tableButtons}
        actions={userActions}
        loading={loading}
        noItemsKey="No Templates"
        filters={filters}
        pages={tableMetaData?.last ?? 1}
        updateFilters={updateFilters}
        sessionSettings={{ name: 'EMAILTEMPLATES' }}
        className="adminTable"
      />
    </div>
  );
}
