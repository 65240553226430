/* eslint-disable camelcase */
import { Icon, Popup, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import useHyperscience from '@hooks/useHyperscience';

export default function ActionsCell({ row, table, columnNames }) {
  const { addRow, removeRow, newRows, anyNewRowEmpty } = useHyperscience();
  const { t } = useTranslation();

  const onDeleteRowClick = (evt) => {
    evt.stopPropagation();
    removeRow(row.id);
  };

  const onAddRowClick = (evt) => {
    evt.stopPropagation();
    const newRowId = generateRowId();
    const newRow = {
      id: newRowId,
      table_id: table.id,
      isNew: true,
      cells: columnNames.map((column_name, index) => ({
        id: generateCellId(index),
        document_table_row_id: newRowId,
        column_name,
        normalized: '',
      })),
    };
    addRow(newRow);
  };

  const generateRowId = () => {
    const rowIds = table.rows.map((_row) => _row.id);
    let newId = 0;
    if (
      typeof rowIds.at(-1) === 'string' &&
      rowIds.at(-1).includes('new_row')
    ) {
      const lastNewId = parseInt(rowIds.at(-1).split('_').at(-1), 10);
      newId = lastNewId + 1;
    }
    newId += newRows.length;
    return `new_row_${newId}`;
  };

  const generateCellId = (index) => {
    const lastCellId = table.rows.at(-1).cells.at(-1).id;
    let newCellId =
      typeof lastCellId === 'string'
        ? parseInt(lastCellId.split('_').at(-1), 10)
        : 0;
    newCellId = newCellId + newRows.length * columnNames.length + 1 + index;
    return `new_cell_${newCellId}`;
  };

  return (
    <Table.Cell>
      {row.isNew && (
        <Popup
          inverted
          trigger={
            <Icon
              style={{ cursor: 'pointer' }}
              name="minus square outline"
              color="red"
              size="large"
              onClick={onDeleteRowClick}
            />
          }
          content={t('DeleteRow')}
        />
      )}
      {!anyNewRowEmpty() && (
        <Popup
          inverted
          trigger={
            <Icon
              style={{ cursor: 'pointer' }}
              name="plus square outline"
              color="green"
              size="large"
              onClick={onAddRowClick}
            />
          }
          content={t('AddNewRow')}
        />
      )}
    </Table.Cell>
  );
}
