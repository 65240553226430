import Jsona from 'jsona';

import api from '@services/axios';

const dataFormatter = new Jsona();

export const updateDocumentType = async (
  workItemId,
  documentId,
  documentType,
) => {
  const data = {
    type: 'workItem',
    id: workItemId,
    documents: [
      {
        id: documentId,
        document_type: documentType,
        layout_name: documentType,
      },
    ],
  };

  const jsonAPIData = dataFormatter.serialize({ stuff: data });

  const response = await api
    .jsonAPI()
    .patch(`work_items/${workItemId}/documents`, jsonAPIData);
  return response.data;
};
