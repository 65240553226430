import api from '@services/axios';

export const getTags = () => {
  return api.jsonAPI().get('/tags');
};

export const fetchStatuses = () => {
  return api.restAPI().get('/work_items/status');
};

export const fetchWorkItems = (filters) => {
  return api.jsonAPI().get('/work_items', { params: filters });
};

export const approveWorkItems = (selectedWorkItemsIds) => {
  return api.jsonAPI().post(`/output_batches/approve`, {
    data: { work_item_ids: selectedWorkItemsIds },
  });
};

export const restoreWorkItems = (selectedWorkItemsIds) => {
  return api.restAPI().patch(`work_items/mass_restore`, {
    id: selectedWorkItemsIds,
  });
};

export const removeWorkItems = (selectedWorkItemsIds) => {
  return api.restAPI().patch(`work_items/mass_destroy`, {
    id: selectedWorkItemsIds,
  });
};

export const mergeWorkItems = (selectedItems, mainId) => {
  return api.jsonAPI().patch(`/work_items/merge`, {
    work_items: selectedItems,
    main: mainId,
  });
};

export const updateTags = (userId, value) => {
  return api.jsonAPI().patch(`/users/${userId}/index_tags`, { tags: value });
};
