import { useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import s from './styles.module.scss';
import { updateAlerts } from './api/api';

const createAlertsHash = (alerts, checkedAlerts) => {
  return alerts.map((alert) => {
    const isChecked = checkedAlerts.includes(alert.id);

    return { ...alert, checked: isChecked };
  });
};

const Alerts = ({
  alerts: alertsList,
  checkedAlerts,
  onAlertChange,
  readOnly,
}) => {
  const { id } = useParams();

  const [alerts, setAlerts] = useState(() =>
    createAlertsHash(alertsList, checkedAlerts),
  );

  const handleChange = (e, data) => {
    setAlerts((state) => {
      const checkbox = state.find((alert) => alert.name === data.label);
      checkbox.checked = data.checked;
      handleUpdateAlerts();
      return [...state];
    });
  };

  const handleUpdateAlerts = async () => {
    try {
      const updatedAlerts = await updateAlerts(id, alerts);
      onAlertChange(updatedAlerts);
    } catch (err) {
      console.error('Failed to update alerts:', err);
    }
  };

  if (!alerts.length) return 'No alerts';

  return (
    <div className={s.alertsWrapper}>
      {alerts.map((alert) => (
        <div key={alert.name} className={s.alertWrapper}>
          <Checkbox
            label={alert.name}
            className={s.alert}
            checked={alert.checked}
            onChange={handleChange}
            disabled={readOnly}
          />
        </div>
      ))}
    </div>
  );
};

export default Alerts;
