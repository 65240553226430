import api from '@services/axios';

export const updatePassword = async (token, password, passwordConfirmation) => {
  try {
    await api
      .restAPI()
      .post(
        `users/update_password?token=${token}&password=${encodeURIComponent(
          password,
        )}&password_confirmation=${encodeURIComponent(passwordConfirmation)}`,
      );
  } catch (err) {
    const serverError =
      err?.response?.data?.messages.join(', ') || 'Something went wrong';
    throw new Error(serverError);
  }
};
