import { useState, useEffect, useCallback, useRef } from 'react';
import { Table } from 'semantic-ui-react';

import HeaderCell from './HeaderCell';
import TableFilters from './Filters/TableFilters';
import { MIN_CELL_WIDTH_PX, CHECKBOX_WIDTH } from './constants';

export default function TableHead({
  columns,
  disableFilterMenu = false,
  ...props
}) {
  const [resizedColumnIndex, setResizedColumnIndex] = useState(null);
  const refsArray = useRef([]);

  const mouseMove = useCallback(
    (e) => {
      const newColumnWidths = columns.map((col, i) => {
        if (i === resizedColumnIndex) {
          const newWidth =
            e.clientX - refsArray.current[i].getBoundingClientRect().left;

          if (
            newWidth >= MIN_CELL_WIDTH_PX ||
            newWidth > refsArray.current[i].offsetWidth
          ) {
            return `${newWidth}px`;
          }
        }
        return `${refsArray.current[i].offsetWidth}px`;
      });

      props.onResize(newColumnWidths);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resizedColumnIndex, columns, MIN_CELL_WIDTH_PX],
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setResizedColumnIndex(null);
    removeListeners();
  }, [setResizedColumnIndex, removeListeners]);

  useEffect(() => {
    if (resizedColumnIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [resizedColumnIndex, mouseMove, mouseUp, removeListeners]);
  return (
    <Table.Header>
      <Table.Row className={resizedColumnIndex ? 'resizing' : undefined}>
        {props.onSelectAll && (
          <Table.HeaderCell
            className="table-header-cell"
            style={{ width: `${CHECKBOX_WIDTH}px` }}
          />
        )}
        {columns.map((column, index) => (
          <HeaderCell
            key={`header_${column.tableKey ?? column.key}`}
            column={column}
            index={index}
            filters={props.filters}
            updateFilters={props.updateFilters}
            resizedColumnIndex={resizedColumnIndex}
            setResizedColumnIndex={setResizedColumnIndex}
            refs={refsArray}
          />
        ))}
      </Table.Row>
      {props.filters && !disableFilterMenu && (
        <TableFilters
          columns={columns}
          filters={props.filters}
          updateFilters={props.updateFilters}
          selectedItems={props.selectedItems}
          selectedAll={props.selectedAll}
          onSelectAll={props.onSelectAll}
        />
      )}
    </Table.Header>
  );
}
